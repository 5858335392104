import React from 'react';
import Owner from '../pages/Owner';
import axios from 'axios';
import { useState, useEffect } from "react"
import "../css/members.css"

const Members = (props) => {
    const [assistants, setAssistants] = useState([])
    const [teachers, setTeachers] = useState([])
    const [technicals, setTechnicals] = useState([])
    const [newUser, setUser] = useState({

        admin_user: "",
        admin_password: "",
        admin_role: "",
        admin_email: "",
        admin_tel: "",
        teacher_subject: ""

    })
    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        const allMembers = async () => {
            try {
                const fetchUsers = await axios.get(`${process.env.REACT_APP_HOST}members`, {headers})

                const uniqueAssistants = [];
                const uniqueTechnicals = [];
                const uniqueTeachers = [];
                // console.log("Data Coming From Server: ", fetchUsers.data)

                fetchUsers.data.forEach((user) => {
                    if (user.admin_role === "assistant" && !uniqueAssistants.some((a) => a.admin_user === user.admin_user)) {
                        uniqueAssistants.push(user);
                    }

                    if (user.admin_role === "technical" && !uniqueTechnicals.some((t) => t.admin_user === user.admin_user)) {
                        uniqueTechnicals.push(user);
                    }

                    if (user.admin_role === "teacher" && !uniqueTeachers.some((t) => t.admin_user === user.admin_user)) {
                        uniqueTeachers.push(user);
                    }
                });

                setAssistants(uniqueAssistants);
                setTechnicals(uniqueTechnicals);
                setTeachers(uniqueTeachers);
            } catch (err) {
                console.log(err)
            }
        }
        allMembers()
    }, [])


    useEffect(() => {
        // console.log(technicals)
        // console.log(teachers)
        // console.log(assistants)
    }, [technicals, teachers, assistants])


    const handleBtn = () => {
        const btns = document.querySelectorAll("form.form-user button")
        btns[0].style.setProperty("display", "block")
        btns[1].style.setProperty("display", "none")
        const form = document.getElementById('frm-add');
        if (form) {
            form.style.setProperty("display", "flex");
        } else {
            console.error("Element with ID 'frm-add' not found.");
        }
    };


    const handleCancel = (e) => {
        e.preventDefault()
        const form = document.getElementById('frm-add');
        if (form) {
            form.style.setProperty("display", "none");
        } else {
            console.error("Element with ID 'frm-add' not found.");
        }
    };


    const handleChange = (e) => {
        setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
        // console.log(newUser)
    }


    const handleAddUser = async (e) => {
        e.preventDefault()
        try {
            const addingUser = await axios.post(`${process.env.REACT_APP_HOST}admin`, newUser, {headers})
            // console.log(addingUser.status)
            document.location.reload()
        } catch (err) {
            console.log(err)
        }

    };


    const handleShow = (e) => {
        const btns = document.querySelectorAll("aside.panel button")
        btns.forEach((btn) => {
            btn.style.cssText = "background-color: #fff; color: #000;"
        })
        document.querySelector("div.teachers").style.setProperty("display", "none");
        document.querySelector("div.technicals").style.setProperty("display", "none");
        document.querySelector("div.assistants").style.setProperty("display", "none");
        if (e.target.textContent === "Teachers") {
            document.querySelector("div.teachers").style.setProperty("display", "flex");
        } else if (e.target.textContent === "Technicals") {
            document.querySelector("div.technicals").style.setProperty("display", "flex");
        } else if (e.target.textContent === "Assistants") {
            document.querySelector("div.assistants").style.setProperty("display", "flex");
        } else {
            console.log("The Element is not found")
        }
        e.currentTarget.style.cssText = "background-color: #000; color: #fff;"

    }

    const handleDelete = async (e) => {
        e.preventDefault()
        try {
            const confirmation = await window.confirm("Are You Sure You Want To Delete This User ?")
            if (confirmation) {
                const deleteUser = await axios.delete(`${process.env.REACT_APP_HOST}admin/${parseInt(e.target.id)}`, {headers})
                window.location.reload()
            } else {
                console.log("There was an error !")
            }


        } catch (err) {
            console.log(err)
        }
    }

    const handleUpdateUser = async (e) => {
        e.preventDefault()
        try {
            const updatingUser = await axios.put(`${process.env.REACT_APP_HOST}admin/${parseInt(e.target.id)}`, newUser, {headers})
            document.location.reload()
        } catch (err) {
            console.log(err)
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        const theId = e.target.id
        const btns = document.querySelectorAll("form.form-user button")
        btns[0].style.setProperty("display", "none")
        btns[1].style.setProperty("display", "block")
        const form = document.getElementById('frm-add');
        document.querySelector("form.form-user button.update-user-btn-form").setAttribute("id", theId);
        if (form) {
            form.style.setProperty("display", "flex");
        } else {
            console.error("Element with ID 'frm-add' not found.");
        }
    }



    return (
        <React.Fragment>
            <Owner />

            <div className='all-members-show'>


                <aside className='panel'>
                    <button onClick={handleShow}>Teachers</button>
                    <button onClick={handleShow}>Assistants</button>
                    <button onClick={handleShow}>Technicals</button>
                </aside>

                <div className='members-data'>
                    <div className='teachers'>
                        {teachers.map((teacher) => (
                            <div className='teacher-column' key={teacher.admin_user}>
                                <div>

                                    <h1>{teacher.admin_user}</h1>
                                    <h2>{teacher.admin_role.toUpperCase()}</h2>
                                    <p>{teacher.teacher_subject}</p>
                                    <p>{teacher.admin_email}</p>
                                    <p>{teacher.admin_tel}</p>
                                    <div className='btn-actions'>
                                        <button onClick={handleDelete} id={teacher.id}>Delete</button>
                                        <button id={teacher.id} onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>


                    <div className='assistants'>
                        {assistants.map((assistant) => (
                            <div className='teacher-column' key={assistant.admin_user}>
                                <div>
                                    <h1>{assistant.admin_user}</h1>
                                    <h2>{assistant.admin_role.toUpperCase()}</h2>
                                    <p>{assistant.admin_email}</p>
                                    <p>{assistant.admin_tel}</p>
                                    <div className='btn-actions'>
                                        <button onClick={handleDelete} id={assistant.id}>Delete</button>
                                        <button id={assistant.id} onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className='technicals'>
                        {technicals.map((technical) => (
                            <div className='teacher-column' key={technical.admin_user}>
                                <div>
                                    <h1>{technical.admin_user}</h1>
                                    <h2>{technical.admin_role.toUpperCase()}</h2>
                                    <p>{technical.admin_email}</p>
                                    <p>{technical.admin_tel}</p>
                                    <div className='btn-actions'>
                                        <button onClick={handleDelete} id={technical.id}>Delete</button>
                                        <button id={technical.id} onClick={handleUpdate}>Update</button>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div className='add-user-container'>
                    <button className='add-user-btn' onClick={handleBtn}>Add User</button>
                </div>

                <div className='add-user-form' id='frm-add'>
                    <form className='form-user'>
                        <input type="text" name='admin_user' placeholder='username:' onChange={handleChange} />
                        <input type="email" name='admin_email' placeholder='email:' onChange={handleChange} />
                        <input type="text" name='admin_tel' placeholder='telephone:' onChange={handleChange} />
                        <input type="text" name='teacher_subject' placeholder='subject: this is for teacher only' onChange={handleChange} />
                        <input type="text" name='admin_password' placeholder='password:' onChange={handleChange} />
                        <select name="admin_role" onChange={handleChange}>
                            <option value="" name="admin_role">Create As:</option>
                            <option value="assistant" name="admin_role">Assistant</option>
                            <option value="technical" name="admin_role">Technical</option>
                            <option value="teacher" name="admin_role">Teacher</option>
                        </select>
                        <button className='add-user-btn-form' onClick={handleAddUser}>Add User</button>
                        <button className='update-user-btn-form' onClick={handleUpdateUser}>Update</button>
                        <button className='cancel-user-btn-form' onClick={handleCancel}>Cancel</button>
                    </form>
                </div>

            </div>

        </React.Fragment>
    );
}


export default Members