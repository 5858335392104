// // Frontend code using React.js

// import React, { useState } from 'react';
// import axios from 'axios';
// import "../css/contact.css"

// const ContactUs = () => {
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         message: ''
//     });

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await axios.post(`${process.env.REACT_APP_HOST}api/send-email`, formData);
//             alert('Your message has been sent successfully!');
//             setFormData({ name: '', email: '', message: '' });
//         } catch (error) {
//             console.error('Error sending message:', error);
//             alert('An error occurred while sending your message. Please try again later.');
//         }
//     };

//     return (
//         <main className='contact-main'>
//             <section className='contact-section'>
//                 <div className="section-header">
//                     <div className="c-container">
//                         <h2>Contact Us</h2>
//                         <p>Besoin d'assistance ou d'informations supplémentaires ? N'hésitez pas à nous contacter rapidement et facilement via le formulaire ci-dessous. Notre équipe se fera un plaisir de répondre à toutes vos questions et demandes dans les plus brefs délais.</p>
//                     </div>
//                 </div>

//                 <div className="c-container">
//                     <div className="row">
//                         <div className="contact-info">
//                             <div className="contact-info-item">
//                                 <div className="contact-info-icon">
//                                     <i className="fas fa-home"></i>
//                                 </div>

//                                 <div className="contact-info-content">
//                                     <h4>Address</h4>
//                                     <p>Targa Eljadida,<br /> Errachidia, Morocco, <br />25000</p>
//                                 </div>
//                             </div>

//                             <div className="contact-info-item">
//                                 <div className="contact-info-icon">
//                                     <i className="fas fa-phone"></i>
//                                 </div>

//                                 <div className="contact-info-content">
//                                     <h4>Phone</h4>
//                                     <p>+212 6 062-263-180</p>
//                                 </div>
//                             </div>

//                             <div className="contact-info-item">
//                                 <div className="contact-info-icon">
//                                     <i className="fas fa-envelope"></i>
//                                 </div>

//                                 <div className="contact-info-content">
//                                     <h4>Email</h4>
//                                     <p>econo_edge2024@gmail.com</p>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="contact-form">
//                             <form onSubmit={handleSubmit}>
//                                 <h2>Send Message</h2>
//                                 <div className="input-box">
//                                     <input type="text" required name="name" value={formData.name} onChange={handleChange} />
//                                     <span>Full Name</span>
//                                 </div>

//                                 <div className="input-box">
//                                     <input type="email" required name="email" value={formData.email} onChange={handleChange} />
//                                     <span>Email</span>
//                                 </div>

//                                 <div className="input-box">
//                                     <textarea required name="message" value={formData.message} onChange={handleChange}></textarea>
//                                     <span>Type your Message...</span>
//                                 </div>

//                                 <div className="input-box">
//                                     <input type="submit" value="Send" />
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </main>

//     );
// };

// export default ContactUs;



import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import "../css/contact.css"

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [sent, setSent] = useState(false); // To track successful submission

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        emailjs.sendForm('extra_stud_admin', 'template_x1i6kne', e.target, 'pFX54Wt0Ch-kIG2q8')
            .then((result) => {
                console.log(result.text);
                setSent(true);
                setFormData({ name: '', email: '', message: '' });
                alert('Your message has been sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('An error occurred while sending your message. Please try again later.');
            });
    };

    return (
        <main className='contact-main'>
            <section className='contact-section'>
                <div className="section-header">
                    <div className="c-container">
                        <h2>Contact Us</h2>
                        <p>Besoin d'assistance ou d'informations supplémentaires ? N'hésitez pas à nous contacter rapidement et facilement via le formulaire ci-dessous. Notre équipe se fera un plaisir de répondre à toutes vos questions et demandes dans les plus brefs délais.</p>
                    </div>
                </div>

                <div className="c-container">
                    <div className="row">
                        <div className="contact-info">
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-home"></i>
                                </div>

                                <div className="contact-info-content">
                                    <h4>Address</h4>
                                    <p><br /> Errachidia, Morocco <br /></p>
                                </div>
                            </div>

                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-phone"></i>
                                </div>

                                <div className="contact-info-content">
                                    <h4>Phone</h4>
                                    <p>+212 6 64-45-73-55</p>
                                </div>
                            </div>

                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-envelope"></i>
                                </div>

                                <div className="contact-info-content">
                                    <h4>Email</h4>
                                    <p>contact.extrastud@gmail.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form">
                            <form onSubmit={handleSubmit}>
                                <h2>Envoyer Message</h2>
                                <div className="input-box">
                                    <input type="text" required name="name" value={formData.name} onChange={handleChange} />
                                    <span>Nome Complet:</span>
                                </div>

                                <div className="input-box">
                                    <input type="email" required name="email" value={formData.email} onChange={handleChange} />
                                    <span>Email</span>
                                </div>

                                <div className="input-box">
                                    <textarea required name="message" value={formData.message} onChange={handleChange}></textarea>
                                    <span>Ecris...</span>
                                </div>

                                <div className="input-box">
                                    <input type="submit" value="Send" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ContactUs;
