import React, { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import axios from "axios"
import Owner from "./Owner"
import "../css/settings.css"
import parental from "../images/icons/parental.png"
import sociaux from "../images/icons/reseaux-sociaux.png"
import myoffers from "../images/icons/offres.png"
import notify from "../images/icons/notify.png"
import subjects from "../images/icons/subject.png"
import block from "../images/icons/blocked.png"
import ads_vid from "../images/icons/marketing-video.png"

import fa from "../images/social-media/facebook.svg";
import insta from "../images/social-media/instagram.svg"
import tik from "../images/social-media/tiktok.svg";
import what from "../images/social-media/whatsapp.svg";
import you from "../images/social-media/youtube.svg";

import "../css/messages.css"


import add_courses from "../images/icons/courses.png"
import courses_online from "../images/icons/course-online.png"
import add_subjects from "../images/icons/subjects.png"
import subjects_online from "../images/icons/subject-online.png"

import levelsEdit from "../images/icons/materials-icons/levels.png"
import coursesEdit from "../images/icons/materials-icons/courses.png"
import optionsEdit from "../images/icons/materials-icons/options.png"
import validateIcon from "../images/icons/veified-user.png";



const Settings = props => {
    const navigate = useNavigate()
    const [messages, setMessages] = useState([{}])
    const [loading, setLoading] = useState(false); // State for loader
    const [offers, setOffers] = useState({
        "offer_title": "",
        "offer_price": "",
        "offer_duration": "",
        "offer_description_01": "",
        "offer_description_02": "",
        "offer_description_03": "",
        "offer_description_04": "",
        "offer_description_05": "",
        "offer_num": ""

    })

    const [subjects, setSubjects] = useState([]); // To store the list of subjects
    const [selectedSubjects, setSelectedSubjects] = useState([]); // To store selected subjects in the form
    const [currentUserId, setCurrentUserId] = useState(null); // To track the user id for editing subjects
    const [showForm, setShowForm] = useState(false); // Control form visibility

    const [reservations, setReservations] = useState([{}])
    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleUpdateSocial = async (e) => {
        e.preventDefault();

        try {
            const socialWeb = e.target.className;
            const newValue = e.currentTarget.previousElementSibling.value;

            if (e.currentTarget.previousElementSibling) {
                const updateLink = await axios.put(`${process.env.REACT_APP_HOST}admin/social/${socialWeb}/${encodeURIComponent(newValue)}`, {}, {headers});
                // console.log(updateLink.status);

                document.querySelectorAll("article.admin-settings div:not(#first-layout) input").forEach((input) => {
                    input.value = "";
                })
            } else {
                console.error("previousElementSibling is null");
            }
        } catch (err) {
            console.error("Axios Error updating!", err);
        }
    };


    useEffect(() => {
        const loadReservation = async (e) => {
            try {
                const loadData = await axios.get(`${process.env.REACT_APP_HOST}reservation/prof`, {headers} )
                // console.log(loadData.status)
                // console.log(loadData.data)
                setReservations(loadData.data)
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ")
            }

        }
        loadReservation()
    }, [])


    const handleClick = (e) => {
        e.preventDefault()
        const element = e.target.closest("div#first-layout")
        if (element) document.getElementById(`${element.className}`).style.setProperty("display", "block")
        const allSettings = document.querySelectorAll("article.admin-settings div#first-layout, article.admin-settings div#first-layout")
        allSettings.forEach((setting) => {
            setting.style.setProperty("display", "none")
        })
    }


    const handleOffers = async (e) => {
        e.preventDefault()
        try {

            const sendOffers = await axios.post(`${process.env.REACT_APP_HOST}app/offers`, offers, {headers})
            // console.log(sendOffers.status)
            document.querySelectorAll("article.admin-settings form input").forEach((input) => {
                input.value = "";
            })

        } catch (err) {
            if (err) console.log("axios: " + err)
        }
    }


    const handleChange = (e) => {
        setOffers(prev => ({ ...prev, [e.target.name]: e.target.value }))
        // console.log(offers)
    }


    const handleBack = (e) => {
        const allSettings = document.querySelectorAll("article.admin-settings div#first-layout")
        allSettings.forEach((setting) => {
            setting.style.setProperty("display", "flex")
        })
        const subSettings = document.querySelectorAll("div.show-after-click")
        subSettings.forEach((setting) => {
            setting.style.setProperty("display", "none")
        })
    }


    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // const handleUpload = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('video', file);

    //         await axios.post(`${process.env.REACT_APP_HOST}upload`, formData, {headers} , {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         console.log('File uploaded successfully');
    //         window.location.reload();
    //     } catch (error) {
    //         console.error('Error uploading file', error);
    //     }
    // };

    const handleUpload = async () => {
        if (!file) {
          alert("Please select a video file to upload.");
          return;
        }
    
        setLoading(true); // Show loader when upload starts
    
        try {
          const formData = new FormData();
          formData.append("video", file);
    
          await axios.post(`${process.env.REACT_APP_HOST}upload`, formData, {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data",
            },
          });
    
          setLoading(false); // Hide loader when upload completes
          alert("Video uploaded successfully!"); // Show success alert
        } catch (error) {
          console.error("Error uploading file", error);
          setLoading(false); // Hide loader on error
          alert("Failed to upload the video.");
        }
      };




    const [blockeds, setBlockeds] = useState([{}])
 // Function to fetch the list of blocked users
 const fetchBlockeds = async () => {
    try {
        const loadData = await axios.get(`${process.env.REACT_APP_HOST}parents/blocked`, {headers});
        // console.log(loadData.status);
        // console.log(loadData.data);
        setBlockeds(loadData.data);
    } catch (err) {
        console.error("Error fetching blocked users:", err);
    }
};

// Load blocked users on component mount
useEffect(() => {
    fetchBlockeds();
}, []);

// Function to handle blocking/unblocking
const handleBlock = async (e) => {
    e.preventDefault();
    const confirmVerify = window.confirm("Do You Want To Unblock This User?");
    if (confirmVerify) {
        try {
          

            // Unblock the user
            await axios.put(`${process.env.REACT_APP_HOST}parents/unblock/${e.target.id}`, null, { headers });

            // Fetch the updated list of blocked users
            fetchBlockeds();
        } catch (err) {
            console.error("Server Axios Error:", err);
            alert("Failed to unblock the user. Please try again.");
        }
    }
};


// Show the subject selection form
const handleSubjectsOffer = (userId) => {
    setCurrentUserId(userId);
    setShowForm(true);
};

  // Fetch available subjects from the endpoint
  useEffect(() => {
    const fetchSubjects = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST}subjects`, { headers });
            if (response.status === 200) {
                setSubjects(response.data);
            }
        } catch (err) {
            console.error("Error fetching subjects:", err);
        }
    };

    fetchSubjects();
}, []);

// Handle the submission of the subjects form
const handleSubjectsSubmit = async (e) => {
    e.preventDefault();

    try {
        await axios.put(
            `${process.env.REACT_APP_HOST}setnewrules`,
            {
                userId: currentUserId,
                including_subjects: selectedSubjects
            },
            { headers }
        );
        setShowForm(false);
        alert("Subjects updated successfully!");
    } catch (err) {
        console.error("Error updating subjects:", err);
    }
};
    


    return (
        <React.Fragment>
            <Owner />
            <article className="admin-settings">
                <div className="Blocked-Students" id="first-layout" onClick={handleClick}>
                    <img src={block} alt="" height={100} className="admin-settings-imgs" />
                    <p>Interface du Blockage</p>
                </div>

                <div className="Manage-Offers" id="first-layout" onClick={handleClick}>
                    <img src={myoffers} alt="" height={100} className="admin-settings-imgs" />
                    <p>Management d'offres</p>
                </div>

                <div className="Content-Management" id="first-layout" onClick={handleClick}>
                    <img src={notify} alt="" height={100} className="admin-settings-imgs" />
                    <p>Contenus Management</p>
                </div>

                <div className="Manage-Subjects" id="first-layout" onClick={handleClick}>
                    <img src={subjects_online} alt="" height={100} className="admin-settings-imgs" />
                    <p>Matiére Management</p>
                </div>

                <div className="Manage-Social-Media" id="first-layout" onClick={handleClick}>
                    <img src={sociaux} alt="" height={100} className="admin-settings-imgs" />
                    <p>Interface Social Media</p>
                </div>

                <div className="Réservation-Prof" id="first-layout" onClick={handleClick}>
                    <img src={parental} alt="" height={100} />
                    <p>Réservation Matiéres</p>
                </div>

                <div className="Manage-ads" id="first-layout" onClick={handleClick}>
                    <img src={ads_vid} alt="" height={100} />
                    <p>Interface -ADS</p>
                </div>
            </article>

            <div id="Blocked-Students" className="show-after-click">


                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <article className="admin-settings">
                    <table className="messages-table">
                        <tr className="messages-heads">
                            <td>Id</td>
                            <td>Nome Complet</td>
                            <td>Utilisateur</td>
                            <td>Age</td>
                            <td>Email</td>
                            <td>Télephone</td>
                            <td>City</td>
                            <td>Branche</td>
                            <td>Option</td>
                            <td>Validation</td>
                            <td>Edit</td>
                        </tr>
                        {blockeds.map((blocked) => (
                            <tr key={blocked.id} className="messages-rows">
                                <td className="id-field">{blocked.id}</td>
                                <td>{blocked.fullname}</td>
                                <td>{blocked.username}</td>
                                <td>{blocked.age} Ans</td>
                                <td>{blocked.email}</td>
                                <td>{blocked.phone}</td>
                                <td>{blocked.city}</td>
                                <td>{blocked.level}</td>
                                <td>{blocked.option_field}</td>
                                <td><button id={blocked.id} className="unblock-user" onClick={handleBlock}>Unblock</button></td>
                                <td>
                                <button
                                    onClick={() => handleSubjectsOffer(blocked.id)}
                                    className="editOffer"
                                >
                                    Custom <img src={validateIcon} alt="Green Validate Icon" height={23} />
                                </button>
                            </td>
                            </tr>
                        ))}
                    </table>


                      {/* Form to edit subjects */}
                      {showForm && (
                <div className="subject-form">
                    <h3>Edit Subjects for User ID: {currentUserId}</h3>
                    <form onSubmit={handleSubjectsSubmit}>
                        <label>Select Subjects:</label>
                        <select
                            multiple
                            value={selectedSubjects}
                            onChange={(e) =>
                                setSelectedSubjects(
                                    Array.from(e.target.selectedOptions, option => option.value)
                                )
                            }
                        >
                            {subjects.map((subject) => (
                                <option key={subject.id} value={subject.subject_name}>
                                    {subject.subject_name}
                                </option>
                            ))}
                        </select>
                        <button type="submit" className="updateed">Update Subjects</button>
                        <button type="button" className="messageeee" onClick={() => setShowForm(false)}>
                            Cancel
                        </button>
                    </form>
                </div>
            )}
                </article>
               
            </div>




            <div id="Manage-Offers" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <article className="admin-settings">
                    <form className="offers-form">
                        <h1>Offers</h1>
                        <input type="text" placeholder="Nome d'offre: " name="offer_title" onChange={handleChange} />
                        <input type="text" placeholder="Prix d'offre: " name="offer_price" onChange={handleChange} />
                        <input type="text" placeholder="Durée d'offre: " name="offer_duration" onChange={handleChange} />
                        <input type="text" placeholder="Contenus: " name="offer_description_01" onChange={handleChange} />
                        <input type="text" placeholder="Qualité des videos: " name="offer_description_02" onChange={handleChange} />
                        <input type="text" placeholder="Autre 03: " name="offer_description_03" onChange={handleChange} />
                        <input type="text" placeholder="Autre 04: " name="offer_description_04" onChange={handleChange} />
                        <input type="text" placeholder="Autre 05: " name="offer_description_05" onChange={handleChange} />
                        <select name="offer_num" required onChange={handleChange}>
                            <option>Select Offer To Update: </option>
                            <option value="1" name="offer_num">Offre 01</option>
                            <option value="2" name="offer_num">Offre 02</option>
                            <option value="3" name="offer_num">Offre 03</option>
                            <option value="4" name="offer_num">Offre 04</option>
                            <option value="5" name="offer_num">Offre 05</option>
                        </select>
                        <button className="set-offer-btn" onClick={handleOffers}>Appliquer Offre</button>
                    </form>
                </article>
            </div>


            <div id="Content-Management" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <article className="admin-settings" id="content-management-id">
                    <div className="setup-content">
                        <Link to="new/level">
                            <div className="add-level">
                                <img src={levelsEdit} alt="" height={80} className="admin-settings-imgs" />
                                <p>Nouvelle Branche</p>
                            </div>
                        </Link>


                        <Link to="new/option">
                            <div className="add-option">
                                <img src={optionsEdit} alt="" height={80} className="admin-settings-imgs" />
                                <p>Nouvelle option</p>
                            </div>
                        </Link>


                        <Link to="new/course">
                            <div className="add-course">
                                <img src={coursesEdit} alt="" height={80} className="admin-settings-imgs" />
                                <p>New Course</p>
                            </div>
                        </Link>
                    </div>
                </article>
            </div>







            <div id="Manage-Subjects" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <article className="admin-settings">
                    <div className="online-content">
                        <Link to="online/subjects">
                            <div className="Show-Subjects">
                                <img src={subjects_online} alt="" height={100} className="admin-settings-imgs" />
                                <p>Matiéres disponibles</p>
                            </div>
                        </Link>


                        <Link to="online/courses">
                            <div className="Show-Courses">
                                <img src={courses_online} alt="" height={100} className="admin-settings-imgs" />
                                <p>Cours disponibles</p>
                            </div>
                        </Link>
                    </div>

                    <div className="offline-content">
                        <Link to="manage/subjects">
                            <div className="Manage-Subjects">
                                <img src={add_subjects} alt="" height={100} className="admin-settings-imgs" />
                                <p>Ajouter Matiére</p>
                            </div>
                        </Link>
                        <Link to="manage/courses">
                            <div className="Manage-Courses">
                                <img src={add_courses} alt="" height={100} className="admin-settings-imgs" />
                                <p>Ajouter Cours</p>
                            </div>
                        </Link>
                    </div>
                </article>
            </div>




            <div id="Manage-Social-Media" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <article className="admin-settings">
                    <div className="Manage-Facebook" onClick={handleClick}>
                        <img src={fa} alt="" height={60} className="admin-settings-imgs" />
                        <input className="inputed" type="text" placeholder="Facebook Link Here: " />
                        <button className="facebook" onClick={handleUpdateSocial}>Update</button>
                    </div>

                    <div className="Manage-Instagram">
                        <img src={insta} alt="" height={60} className="admin-settings-imgs" />
                        <input className="inputed" type="text" placeholder="Instagram Link Here: " />
                        <button className="instagram" onClick={handleUpdateSocial}>Update</button>
                    </div>

                    <div className="Teacher-Youtube">
                        <img src={you} alt="" height={60} className="admin-settings-imgs" />
                        <input className="inputed" type="text" placeholder="Youtube Link Here: " />
                        <button className="youtube" onClick={handleUpdateSocial}>Update</button>
                    </div>

                    <div className="Manage-Tiktok">
                        <img src={tik} alt="" height={60} className="admin-settings-imgs" />
                        <input className="inputed" type="text" placeholder="Tiktok Link Here: " />
                        <button className="tiktok" onClick={handleUpdateSocial}>Update</button>
                    </div>

                    <div className="Manage-Whatsapp" onClick={handleClick}>
                        <img src={what} alt="" height={60} className="admin-settings-imgs" />
                        <input className="inputed" type="text" placeholder="Whatsapp Link Here: " />
                        <button className="whatsapp" onClick={handleUpdateSocial}>Update</button>
                    </div>
                </article>
            </div>






            <div id="Réservation-Prof" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                <br />
                <table className="messages-table">
                    <tr className="messages-heads">
                        <td>Id</td>
                        <td>Last Name</td>
                        <td>First Name</td>
                        <td>Level</td>
                        <td>Option</td>
                        <td>Phone</td>
                        <td>Subject</td>
                    </tr>
                    {reservations.map((rsv) => (
                        <tr key={rsv.id} className="messages-rows">
                            <td className="id-field">{rsv.id}</td>
                            <td>{rsv.lastName}</td>
                            <td>{rsv.name}</td>
                            <td>{rsv.level}</td>
                            <td>{rsv.opt}</td>
                            <td>{rsv.phone}</td>
                            <td>{rsv.subject}</td>
                        </tr>
                    ))}
                </table>
            </div>


            <div id="Manage-ads" className="show-after-click">
                <div className="return">
                    <button className="back-to" onClick={handleBack}>Retour</button>
                </div>
                {/* <article className="admin-settings" id="vid-ads">
                    <div className="vid-upload-ads">
                        <h1>Video Upload</h1>
                        <input type="file" onChange={handleFileChange} />
                        <button onClick={handleUpload}>Upload</button>
                    </div>
                </article> */}

                <article className="admin-settings" id="vid-ads">
                <div className="vid-upload-ads">
                <img src={ads_vid} alt="" height={100} />
            <input type="file" onChange={handleFileChange} accept="video/*" />
            <button onClick={handleUpload} disabled={loading}>
              Upload
            </button>
          </div>
            
          {/* Loader */}
          {loading && <div className="loader">Uploading...</div>}
                </article>


                
            </div>
        </React.Fragment>
    )
}

export default Settings