

import { React, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../css/dev.css";
import developerImage from "../images/jpg/DevPic.jpg";
import emailjs from "emailjs-com";

const Dev = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [sent, setSent] = useState(false); // To show success message

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm("adil_projects_0", "template_m42tphr", e.target, "OSmgehezYDl-baxb9")
      .then((result) => {
        console.log(result.text);
        setSent(true);
        setFormData({ name: '', email: '', message: '' });
      }, (error) => {
        console.log(error.text);
        alert('An error occurred while sending your message. Please try again later.');
      });
  };

  return (
    <>
      <section className="developer">
        <h2 className='about-page'>Développeur</h2>
        <div className="developer-card">
          {/* <img src={developerImage} height={320} width={320} alt="Mrabti Adil" /> */}
          <h3 className='about-page'>Mr.Mrabti Adil</h3>
          <p>Mrabti Adil est le développeur principal de la plateforme Extra-Stud. Passionné par les technologies web, il a créé une plateforme intuitive et performante.</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <h4 className='about-page'>Contactez-moi</h4>
            <label>
              Nom:
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </label>
            <label>
              Email:
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label>
              Message:
              <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
            </label>
            <button type="submit">Envoyer</button>
            {sent && <p>Votre message a été envoyé avec succès !</p>}
          </form>
          <div className="developer-contact">
            <p>Téléphone: +212 716113484</p>
            <p>Email: mr.adil.projects@gmail.com</p>
            <a href="#"><button className="whatsapp-contact">Contactez-moi via Whatsapp</button></a>
            <a href="http://adil-portfolio.com/"><button className="My-Resume">Mon Portfolio</button></a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dev;
