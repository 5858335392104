import React, {useState, useEffect} from "react"
import thank_you from "../images/svg/thankyou.svg"
import "../css/thank-you.css"
import  {useNavigate} from "react-router-dom"

const Thank = props => {
    const navigate = useNavigate()

    const goBack = (e) => {
        e.preventDefault()
        // window.location.href = "/"
        navigate("/")
    }
    return(
        <React.Fragment>
            <div className="grand-parent-container">
            <div className="thank-you-parent">
                <div className="thank-you-parent-container">
                    <img src={thank_you} alt="Thank You Image" height={300}/>
                    <h1>Inscription Done Successfully !</h1>
                    <p>Thank You For Inscription We Will Call You Soon 😎</p>
                    <button onClick={goBack}>Go Back</button>
                </div>
            </div>
            </div>
           
        </React.Fragment>
    )
}

export default Thank