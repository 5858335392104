import  {React, useState, useEffect, useRef} from 'react'
import "../css/hi_offer.css"

import {Link, useNavigate} from "react-router-dom"

import axios from "axios"

// import default_logo from "../images/svg/education.svg"
import default_logo from "../images/svg/logo_v5.svg"

function Hi_offer() {
  const navigate = useNavigate()
  const [student, setStudent] = useState({
    fullname: "",
    username: "",
    age: "",
    email: "",
    phone: "",
    password: "",
    city: "",
    level: "",
    option_field: "",
    offer_field: "",
    })

  const [Levels, setLevels] = useState([])
  const [MyCourses, setCourses] = useState([])
  const [Options, setOptions] = useState([])
  const [offers, setOffers] = useState([])

  const [selectedOffer, setSelectedOffer] = useState(decodeURIComponent(window.location.hash.slice(1)))
  useEffect(() => {

      
    const submit_data_func = async(e) => {
    
      try{
    
     
      const forLevels = await axios.get(`${process.env.REACT_APP_HOST}new/levels`)
      const forOptions = await axios.get(`${process.env.REACT_APP_HOST}new/options`)
      const forCourses = await axios.get(`${process.env.REACT_APP_HOST}new/courses`)
      const forOffers = await axios.get(`${process.env.REACT_APP_HOST}app/active_offers`)
      if(forLevels.status === 200) {
          setLevels(forLevels.data)
      }
    
      if(forOptions.status === 200) {
        setOptions(forOptions.data)
    }
    
    if(forCourses.status === 200) {
      setCourses(forCourses.data)
    }
    
    if(forOffers.status === 200) {
      setOffers(forOffers.data)
    }
    
    }catch(err){
      if(err) {
          console.log(err)
      }
    }
    }
          submit_data_func()
      },[])

      const handleChange = (e) => {
        setStudent(prev => ({...prev, [e.target.name]: e.target.value}))
        // console.log(student)
    }

      const handleAddStudent = async(e) => {
        e.preventDefault()
          try {
            const postOperation = await axios.post(`${process.env.REACT_APP_HOST}parents`, student)
            
            if(postOperation.status === 200) {
            
              let myError = document.querySelector("div.myMessageContainer")
              if(myError) {
                myError.remove()
              }
              setTimeout(() =>  {
                navigate("/redirect/thanks")
              },1200)
            }else {
             
              let myError = document.querySelector("div.myMessageContainer")
              if(myError) {
                return null;
              }else {
                let myMessageContainer = document.createElement("div")
                myMessageContainer.className = "myMessageContainer"
                let myMessageElement = document.createElement("h1")
                let message = "There was a problem Try Again Please"
                myMessageElement.textContent = message
                myMessageContainer.appendChild(myMessageElement)
                myMessageContainer.style.cssText = "position:absolute;top:2%;display: flex; align-items: center; align-content: center; justify-content: center;left: -10%;width: 60%;"
                myMessageElement.style.cssText = "display: flex;align-items: center;align-content: center; justify-content: center;text-align: center; font-weight: bolder; font-family: cursive; color: red; font-size: 20px;"
                document.body.appendChild(myMessageContainer)
              }
            }
        }catch(err) {
            if(err) {
              let myError = document.querySelector("div.myMessageContainer")
              if(myError) {
                return null;
              }else {
                let myMessageContainer = document.createElement("div")
                myMessageContainer.className = "myMessageContainer"
                let myMessageElement = document.createElement("h1")
                // let message = "There was a problem Try Again Please"
                let message = err.response.data.message || "There was a problem Try Again Please"
                myMessageElement.textContent = message
                myMessageContainer.appendChild(myMessageElement)
                myMessageContainer.style.cssText = "position:absolute;top:2%;display: flex; align-items: center; align-content: center; justify-content: center;left: -10%;width: 60%;"
                myMessageElement.style.cssText = "display: flex;align-items: center;align-content: center; justify-content: center;text-align: center; font-weight: bolder; font-family: cursive; color: red; font-size: 20px;"
                document.body.appendChild(myMessageContainer)
              }
             
            }
        }
      }


      const handleStorage = () => {
        window.localStorage.removeItem("offer_price");
        window.localStorage.removeItem("offer_duration");
        window.localStorage.removeItem("offer_quality");
        window.localStorage.removeItem("offer_content");
        window.localStorage.removeItem("offer_functions");
        window.localStorage.removeItem("offer_others");
      }

    




    
  return (
        <>
        <div className='offers-preview-container'>
        <div id="wrapper">
      <div id="left">
        <div id="signin">
          <div className="logo">
            {/* <img src={default_logo} alt="Sluralpright" height={100} /> */}
            <h1 className='logo-of-offers'>Extra-Stud</h1>
          </div>
          <form>
            <div>
              <label>Nome Complet:</label>
              <input type="text" className="text-input" name="fullname" onChange={handleChange}/>
            </div>

            <div>
              <label>Utilisateur:</label>
              <input type="text" className="text-input" name="username" onChange={handleChange}/>
            </div>
            <div>
              <label>Mot De Passe:</label>
              <input type="password" className="text-input" name="password" onChange={handleChange}/>
            </div>

            <div>
              <label>Email:</label>
              <input type="email" className="text-input" name="email" onChange={handleChange}/>
            </div>

            <div>
              <label>Numéro Tel:</label>
              <input type="text" className="text-input" name='phone'  onChange={handleChange}/>
            </div>

            <div>
              <label>Age:</label>
              <input type="number" className="text-input" name="age" min="15" max="99"  onChange={handleChange}/>
            </div>

            <div>
              <label>Ville:</label>
              <select id="city" name="city" required className="text-input" onChange={handleChange}>
                  <option value="" selected>Select City: </option>
                  <option value="Casablanca" name="city">Casablanca</option>
                  <option value="Rabat" name="city">Rabat</option>
                  <option value="Marrakech" name="city">Marrakech</option>
                  <option value="Fes" name="city">Fes</option>
                  <option value="Agadir" name="city">Agadir</option>
                  <option value="Tangier" name="city">Tangier</option>
                  <option value="Meknes" name="city">Meknes</option>
                  <option value="Oujda" name="city">Oujda</option>
                  <option value="Kenitra" name="city">Kenitra</option>
                  <option value="Tetouan" name="city">Tetouan</option>
                  <option value="Safi" name="city">Safi</option>
                  <option value="Mohammedia" name="city">Mohammedia</option>
                  <option value="Khouribga" name="city">Khouribga</option>
                  <option value="Beni Mellal" name="city">Beni Mellal</option>
                  <option value="El Jadida" name="city">El Jadida</option>
                  <option value="Taza" name="city">Taza</option>
                  <option value="Nador" name="city">Nador</option>
                  <option value="Settat" name="city">Settat</option>
                  <option value="Berrechid" name="city">Berrechid</option>
                  <option value="Tanger-Assilah" name="city">Tanger-Assilah</option>
                  <option value="Guelmim" name="city">Guelmim</option>
                  <option value="Errachidia" name="city">Errachidia</option>
                  <option value="Larache" name="city">Larache</option>
                  <option value="Khenifra" name="city">Khenifra</option>
                  <option value="Taroudant" name="city">Taroudant</option>
                  <option value="Essaouira" name="city">Essaouira</option>
                  <option value="AlHoceima" name="city">AlHoceima</option>
                  <option value="Ouarzazate" name="city">Ouarzazate</option>
                  <option value="Sidi Slimane" name="city">Sidi Slimane</option>
                  <option value="Tifelt" name="city">Tifelt</option>
                  <option value="Zagora" name="city">Zagora</option>
                  <option value="Rissani" name="city">Rissani</option>
                  <option value="Arfoud" name="city">Arfoud</option>
                  <option value="Berkane" name="city">Berkane</option>
                  <option value="Tadla" name="city">Tadla</option>
                  <option value="Chafchaoun" name="city">Chafchaoun</option>
              </select>
            </div>

            <div>
              <label>Niveaux:</label>
              <select id="level" name="level" required className="text-input" onChange={handleChange}>
                  {/* <option value="" selected>Select Level: </option>
                  <option value="TCB" name="level">TCB</option>
                  <option value="1 BAC" name="level">1 BAC</option>
                  <option value="2 BAC" name="level">2 BAC</option>
                  <option value="FAC" name="level">FAC</option>
                  <option value="ESGI" name="level">ESGI</option> */}
                  {Levels.map((level) => (
                     <option key={level.id} value={level.title} name="level">{level.title}</option>
                  ))}
                  <option value="Autre" name="level">Autre</option>
              </select>
            </div>

            <div>
              <label>Options:</label>
              <select id="option_field" name="option_field" required className="text-input" onChange={handleChange}>
                    <option value="">Selectionner un option: </option>
                    {/* <option value="Tronc Commun Biof" name="option_field">Tronc Commun Biof</option>
                    <option value="1BAC --SGC" name="option_field">1BAC --SGC</option>
                    <option value="1BAC --ECO" name="option_field">1BAC --ECO</option>
                    <option value="1BAC --SM" name="option_field">1BAC --SM</option>
                    <option value="1BAC --EXP" name="option_field">1BAC --EXP</option>
                    <option value="2BAC --SGC" name="option_field">2BAC --SGC</option>
                    <option value="2BAC --ECO" name="option_field">2BAC --ECO</option>
                    <option value="2BAC --PC" name="option_field">2BAC --PC</option>
                    <option value="2BAC --SVT" name="option_field">2BAC --SVT</option>
                    <option value="2BAC --SM-A" name="option_field">2BAC --SM-A</option>
                    <option value="2BAC --SM-B" name="option_field">2BAC --SM-B</option>
                    <option value="Faculté ECO" name="option_field">Faculté ECO</option>
                    <option value="Faculté SGC" name="option_field">Faculté SGC</option>
                    <option value="Formation" name="option_field">Formation</option> */}
                    {Options.map((option) => (
                        <option key={option.id} value={option.title} name="option_field">{option.title}</option>

                    ))}


                <optgroup id="option_field" name="option_field" required className="parent">
                    <option value="">Selectionner une formation: </option>
                    {MyCourses.map((course) => (
                        <option key={course.id} value={course.title} name="option_field">{course.title}</option>

                      ))}
                </optgroup>
             
            </select>
            </div>

            <div>
              <label>Offres:</label>
              <select id="offer_field" name="offer_field" required className="text-input" onChange={handleChange}>
              <option selected value="" >Selectionner une offre: </option>
              
                <option value={selectedOffer} name="offer_field">{selectedOffer}</option>
              
            </select>
            </div>

            <button type="submit" className="primary-btn" onClick={handleAddStudent}>Inscription</button>
          </form>
        </div>
        <footer id="main-footer">
          <p>Copyright &copy; 2024, EXTRA-STUD All Rights Reserved</p>
          <div>
            <a href="#">terms of use</a> | <a href="#">Privacy Policy</a>
          </div>
        </footer>
      </div>
      <div id="right">
        <div id="showcase">
          <div className="showcase-content">
            <img src={default_logo}  className='logo-indentity'/>

            <h1 className="showcase-text">
              Extra-Stud We Will Learn <strong>together</strong>
            </h1>
            <div className='description_offer'>
    <div className='offer_include'>
        <h2>Offer Selected is: <strong>{selectedOffer}</strong></h2>
        <div className='offer-details'>
            <div className="offer-item"><p>Prix: </p><span>{window.localStorage.getItem("offer_price")}</span></div>
            <div className="offer-item"><p>Durée: </p><span>{window.localStorage.getItem("offer_duration")}</span></div>
            <div className="offer-item"><p>Contenus:</p><span>{window.localStorage.getItem("offer_content")}</span></div>
            <div className="offer-item"><p>Quality: </p><span>{window.localStorage.getItem("offer_quality")}</span></div>
            <div className="offer-item"><p>Fonctionalités: </p><span>{window.localStorage.getItem("offer_functions")}</span></div>
            <div className="offer-item"><p>Autres: </p><span>{window.localStorage.getItem("offer_others")}</span></div>
        </div>
    </div>
</div>

            <Link href="#" onClick={handleStorage} className="secondary-btn" to={"/"}>Retour</Link>
          </div>
        </div>
      </div>
    </div>
        </div>
        <script src="http://localhost:8097"></script>
   
        </>
  )
}


export default Hi_offer
