import React, {useState, useEffect, useRef, useMemo} from "react"
import {Link, useNavigate} from "react-router-dom"
import axios from "axios"
import "../css/secret_dash.css"


 const SecretDash = props => {
    return(
        <>
            <h1>Hello Dear Parents  {window.localStorage.getItem("active-user")}</h1>
        </>
        
    )
}

export default SecretDash
