// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import '../css/PlayerPlayList.css';
// import Pause from "../images/svg/pause.svg";
// import Play from "../images/svg/play.svg";

// const PlayerPlayList = () => {
//   const [currentVideo, setCurrentVideo] = useState({
//     src: '',
//     title: ''
//   });
//   const [videos, setVideos] = useState([]);

//   useEffect(() => {
//     // Fetch video data from the server
//     axios.get('http://localhost:5000/api/courses/videos')
//       .then(response => {
//         setVideos(response.data);
//         if (response.data.length > 0) {
//           setCurrentVideo({
//             src: `${response.data[0].src}?dnt=1&muted=1`,
//             title: response.data[0].title
//           });
//           const initialVideoElement = document.querySelectorAll('.video')[0];
//           if (initialVideoElement) {
//             initialVideoElement.classList.add('active');
//             initialVideoElement.querySelector('img').src = Pause;
//           }
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching video data:', error);
//       });
//   }, []);

//   useEffect(() => {
//     const videoPlaylist = document.querySelector('.video-playlist .videos');
//     if (videoPlaylist) {
//       videoPlaylist.innerHTML = ''; // Clear existing videos
//       videos.forEach((video, i) => {
//         let videoElement = document.createElement('div');
//         videoElement.className = 'video';
//         videoElement.dataset.id = video.id;
//         videoElement.innerHTML = `
//           <img src=${Play} alt="">
//           <p>${i + 1 > 9 ? i + 1 : '0' + (i + 1)}. </p>
//           <h3 class="title">${video.title}</h3>
//           <p class="time">${video.duration}</p>
//           <br><br><br>
//           <p class="desc">${video.description}</p>
//         `;
//         videoElement.addEventListener('click', () => handleVideoClick(video));
//         videoPlaylist.appendChild(videoElement);
//       });

//       const initialVideoElement = document.querySelectorAll('.video')[0];
//       if (initialVideoElement) {
//         initialVideoElement.classList.add('active');
//         initialVideoElement.querySelector('img').src = Pause;
//       }
//     }
//   }, [videos]);

//   const handleVideoClick = (selectedVideo) => {
//     const allVideos = document.querySelectorAll('.video');
//     allVideos.forEach((videoElement) => {
//       videoElement.classList.remove('active');
//       videoElement.querySelector('img').src = Play;
//     });

//     const clickedVideoElement = document.querySelector(`.video[data-id="${selectedVideo.id}"]`);
//     if (clickedVideoElement) {
//       clickedVideoElement.classList.add('active');
//       clickedVideoElement.querySelector('img').src = Pause;
//     }

//     setCurrentVideo({
//       src: `${selectedVideo.src}?dnt=1&muted=1`,
//       title: selectedVideo.title
//     });
//   };

//   return (
//     <main className="container">
//       <section className="main-video">
//         <iframe src={currentVideo.src} width="500px" height="500px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" ></iframe>
//         <h3 className="title">{currentVideo.title}</h3>
//       </section>

//       <section className="video-playlist">
//         <h3 className="title">Title of Video Playlist</h3>
//         <p>{videos.length} lessons &nbsp; . &nbsp; {videos.reduce((total, video) => total + parseFloat(video.duration), 0)}min</p>
//         <div className="videos"></div>
//       </section>
//     </main>
//   );
// };

// export default PlayerPlayList;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/PlayerPlayList.css';
import Pause from "../images/svg/pause.svg";
import Play from "../images/svg/play.svg";

const PlayerPlayList = () => {
  const [currentVideo, setCurrentVideo] = useState({
    src: '',
    title: ''
  });
  const [videos, setVideos] = useState([]);
  const token = localStorage.getItem("userId");
  const headers = {
          Authorization: `Bearer ${token}`,
      };

  const courseDetails = {
    title: 'Professional Course on Web Development',
    offeredBy: 'National Institute of Technology',
    certification: true,
  };

  const userInfo = {
    name: 'John Doe'
  };

  useEffect(() => {
    // Fetch video data from the server
    axios.get(`${process.env.REACT_APP_HOST}api/courses/videos`, {headers})
      .then(response => {
        setVideos(response.data);
        if (response.data.length > 0) {
          setCurrentVideo({
            src: `${response.data[0].src}?dnt=1&muted=1`,
            title: response.data[0].title
          });
          const initialVideoElement = document.querySelectorAll('.video')[0];
          if (initialVideoElement) {
            initialVideoElement.classList.add('active');
            initialVideoElement.querySelector('img').src = Pause;
          }
        }
      })
      .catch(error => {
        console.error('Error fetching video data:', error);
      });
  }, []);

  useEffect(() => {
    const videoPlaylist = document.querySelector('.video-playlist .videos');
    if (videoPlaylist) {
      videoPlaylist.innerHTML = ''; // Clear existing videos
      videos.forEach((video, i) => {
        let videoElement = document.createElement('div');
        videoElement.className = 'video';
        videoElement.dataset.id = video.id;
        videoElement.innerHTML = `
          <img src=${Play} alt="">
          <p>${i + 1 > 9 ? i + 1 : '0' + (i + 1)}. </p>
          <h3 class="title">${video.title}</h3>
          <p class="time">${video.duration}</p>
          <br><br><br>
          <p class="desc">${video.description}</p>
        `;
        videoElement.addEventListener('click', () => handleVideoClick(video));
        videoPlaylist.appendChild(videoElement);
      });

      const initialVideoElement = document.querySelectorAll('.video')[0];
      if (initialVideoElement) {
        initialVideoElement.classList.add('active');
        initialVideoElement.querySelector('img').src = Pause;
      }
    }
  }, [videos]);

  const handleVideoClick = (selectedVideo) => {
    const allVideos = document.querySelectorAll('.video');
    allVideos.forEach((videoElement) => {
      videoElement.classList.remove('active');
      videoElement.querySelector('img').src = Play;
    });

    const clickedVideoElement = document.querySelector(`.video[data-id="${selectedVideo.id}"]`);
    if (clickedVideoElement) {
      clickedVideoElement.classList.add('active');
      clickedVideoElement.querySelector('img').src = Pause;
    }

    setCurrentVideo({
      src: `${selectedVideo.src}?dnt=1&muted=1`,
      title: selectedVideo.title
    });
  };

  return (
    <main className="container">
      <header className="course-header">
        <h1>{courseDetails.title}</h1>
        <div className="course-meta">
          <p>Instructor: {userInfo.name}</p>
          <p>Offered by: {courseDetails.offeredBy}</p>
          <p>Certification: {courseDetails.certification ? 'Yes' : 'No'}</p>
        </div>
      </header>

      <section className="main-video">
        <iframe src={currentVideo.src} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"></iframe>
        <h3 className="title">{currentVideo.title}</h3>
      </section>

      <section className="video-playlist">
        <h3 className="title">Course Playlist</h3>
        <p>{videos.length} lessons &nbsp; . &nbsp; {videos.reduce((total, video) => total + parseFloat(video.duration), 0)} min</p>
        <div className="videos"></div>
      </section>

      <footer className="course-footer">
        <h3>Course Details</h3>
        <p><strong>Instructor:</strong> {userInfo.name}</p>
        <p><strong>Offered by:</strong> {courseDetails.offeredBy}</p>
        <p><strong>Certification:</strong> {courseDetails.certification ? 'Yes' : 'No'}</p>
        <p><strong>Description:</strong> This is a comprehensive course on Web Development covering all the essential topics needed to become a skilled web developer.</p>
      </footer>
    </main>
  );
};

export default PlayerPlayList;
