import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

 // Fix import statement

const AuthContext = createContext();

export const Auth_Provider = ({ children }) => {
  const [authenticated_user, setAuthenticated] = useState(false);
  const [user_id, setUserId] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated from storage on component mount
    const storedAuth = localStorage.getItem('authenticated_user');
    const storedUserId = localStorage.getItem('userId_2');

    if (storedAuth && storedUserId) {
      setAuthenticated(true);
      setUserId(storedUserId);
      if (storedUserId) {
        try {
          const decodedToken = jwtDecode(storedUserId); // Fix token decoding
          // console.log('Decoded Token:', decodedToken);
          const expirationTime = decodedToken.exp * 1000;
          // console.log('Expiration Time:', expirationTime);
          const expirationTimeInMilliseconds = expirationTime;
        const expirationTimeInMinutes = expirationTimeInMilliseconds / (1000 * 60);
        // console.log(expirationTimeInMinutes)
        // console.log('Decoded Token:', decodedToken);

        // console.log('Expiration Time:', expirationTime);
        

          if (Date.now() < expirationTime) {
            // Token is not expired, include it in the request headers
            const headers = {
              Authorization: `Bearer ${storedUserId}`,
            };
            console.log('Token Valid User !!!!!!!!!!');

            // Make authenticated request using 'headers'
            // ...
          } else {
            // Token is expired, handle reauthentication or redirect to the login page
            console.log('Token has expired. Redirect to login page.');
            setUserId(null);
            setAuthenticated(false);
            localStorage.removeItem('authenticated');
            localStorage.removeItem('userId_2');
          }
        } catch (error) {
          console.error('Error decoding JWT:', error);
          setUserId(null);
          setAuthenticated(false);
          localStorage.removeItem('authenticated');
          localStorage.removeItem('userId_2');
          // Handle decoding error, e.g., token is not a valid JWT
        }
      } else {
        // Token is not present, handle authentication or redirect to the login page
        console.log('No Token Found');
        setUserId(null);
        setAuthenticated(false);
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userId_2');
      }
    }

  }, [user_id]); // Add userId to the dependency array

  const login = (id) => {
    setUserId(id);
    setAuthenticated(true);
    localStorage.setItem('authenticated_user', 'true');
    localStorage.setItem('userId_2', id);
  };

  const logout = () => {
    setUserId(null);
    setAuthenticated(false);
    localStorage.removeItem('authenticated_user');
    localStorage.removeItem('userId_2');
  };

 

  return (
    <AuthContext.Provider
      value={{
        authenticated_user,
        setAuthenticated,
        user_id,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthLogin = () => useContext(AuthContext);
