import {React, useState, useEffect, useRef} from "react"

import {useNavigate, Link} from "react-router-dom"

import axios from "axios"
import "../css/newOffers.css"






const OfferPreview = ({}) => {
    const navigate = useNavigate()
    //Offers Setup
    const [firstOffers, setFirstOffers] = useState([{}])
    const [secondOffers, setSecondOffers] = useState([{}])
    const [thirdOffers, setThirdOffers] = useState([{}])
    const [lastOffers, setLastOffers] = useState([{}])

    const [extraOffers, setExtraOffers] = useState([{}])
    const [anotherOffer, setAnotherOffer] = useState([{}])
    const [exceedOffers, setExceedOffers] = useState([{}])

    
    useEffect(() => {
        const getOffers01 = async() => {
            try {
                const myfLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/1`)
                setFirstOffers(myfLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers02 = async() => {
            try {
                const mysLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/2`)
                setSecondOffers(mysLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers03 = async() => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/3`)
                setThirdOffers(mytLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }



        const getOffers04 = async() => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/4`)
                setLastOffers(mytLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers05 = async() => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/5`)
                setExtraOffers(mytLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers06 = async() => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/6`)
                setAnotherOffer(mytLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers07 = async() => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/7`)
                setExceedOffers(mytLinks.data)
            }catch(err) {
                console.log("Internal Axios Error", err)
            }
        }
        getOffers01()
        getOffers02()
        getOffers03()
        getOffers04()
        getOffers05()
        getOffers06()
        getOffers07()
    }, [])


    const handleOffersNavigations = (e, price, duration, quality, content, functions, others) => {
        navigate(`/preview/offer/#${decodeURIComponent(e.target.id)}`);
        window.localStorage.setItem("offer_price", price);
        window.localStorage.setItem("offer_duration", duration);
        window.localStorage.setItem("offer_quality", quality);
        window.localStorage.setItem("offer_content", content);
        window.localStorage.setItem("offer_functions", functions);
        window.localStorage.setItem("offer_others", others);
    }
    
    return (
        <>
            <div className='title-offerss'>
                <div>Nos offres exclusives</div>
            </div>
            <main className='off' id="offers">
                {firstOffers.length > 0 ? (
                        <div className="card-basic">
                        <div className="card-header header-basic">
                        <h1>{firstOffers[0]?.offer_title}</h1>
                        </div>
                        <div className="card-body">
                        <p><h2>{firstOffers[0]?.offer_price}</h2></p>
                        <div className="card-element-hidden-basic">
                            <ul className="card-element-container">
                            <li className="card-element">{firstOffers[0]?.offer_duration}</li>
                            <li className="card-element">{firstOffers[0]?.offer_description_01}</li>
                            <li className="card-element">{firstOffers[0]?.offer_description_02}</li>
                            <li className="card-element">{firstOffers[0]?.offer_description_03}</li>
                            <li className="card-element">{firstOffers[0]?.offer_description_04}</li>
                            <li className="card-element">{firstOffers[0]?.offer_description_05}</li>
                            </ul>
                            <button className="btn-price btn-basic" 
                            id={firstOffers[0]?.offer_title}
                            onClick={(e) => handleOffersNavigations(e, 
                                firstOffers[0]?.offer_price,
                                firstOffers[0]?.offer_duration,
                                firstOffers[0]?.offer_description_02,
                                firstOffers[0]?.offer_description_01,
                                firstOffers[0]?.offer_description_03,
                                firstOffers[0]?.offer_description_04)}
                            >Inscription</button>
                        </div>
                        </div>
                        </div>
                ):("")}
           
            {secondOffers.length > 0 ? (
                <div className="card-standard">
                <div className="card-header header-standard">
                <h1>{secondOffers[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{secondOffers[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-standard">
                    <ul className="card-element-container">
                    <li className="card-element">{secondOffers[0]?.offer_duration}</li>
                    <li className="card-element">{secondOffers[0]?.offer_description_01}</li>
                    <li className="card-element">{secondOffers[0]?.offer_description_02}</li>
                    <li className="card-element">{secondOffers[0]?.offer_description_03}</li>
                    <li className="card-element">{secondOffers[0]?.offer_description_04}</li>
                    <li className="card-element">{secondOffers[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-standard" 
                    id={secondOffers[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        secondOffers[0]?.offer_price,
                        secondOffers[0]?.offer_duration,
                        secondOffers[0]?.offer_description_02,
                        secondOffers[0]?.offer_description_01,
                        secondOffers[0]?.offer_description_03,
                        secondOffers[0]?.offer_description_04)}

                    >Inscription</button>
                </div>
                </div>
            </div>
            ) : ("")}


            {thirdOffers.length > 0 ? (
                <div className="card-premium">
                <div className="card-header header-premium">
                <h1>{thirdOffers[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{thirdOffers[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-premium">
                    <ul className="card-element-container">
                    <li className="card-element">{thirdOffers[0]?.offer_duration}</li>
                    <li className="card-element">{thirdOffers[0]?.offer_description_01}</li>
                    <li className="card-element">{thirdOffers[0]?.offer_description_02}</li>
                    <li className="card-element">{thirdOffers[0]?.offer_description_03}</li>
                    <li className="card-element">{thirdOffers[0]?.offer_description_04}</li>
                    <li className="card-element">{thirdOffers[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-premium" 
                    id={thirdOffers[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        thirdOffers[0]?.offer_price,
                        thirdOffers[0]?.offer_duration,
                        thirdOffers[0]?.offer_description_02,
                        thirdOffers[0]?.offer_description_01,
                        thirdOffers[0]?.offer_description_03,
                        thirdOffers[0]?.offer_description_04)}
                    
                    >Inscription</button>
                </div>
                </div>
                </div>

            ) : ("")}
            
           
            {lastOffers.length > 0 ? (
                <div className="card-premium">
                <div className="card-header header-extra">
                <h1>{lastOffers[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{lastOffers[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-premium">
                    <ul className="card-element-container">
                    <li className="card-element">{lastOffers[0]?.offer_duration}</li>
                    <li className="card-element">{lastOffers[0]?.offer_description_01}</li>
                    <li className="card-element">{lastOffers[0]?.offer_description_02}</li>
                    <li className="card-element">{lastOffers[0]?.offer_description_03}</li>
                    <li className="card-element">{lastOffers[0]?.offer_description_04}</li>
                    <li className="card-element">{lastOffers[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-extra" 
                    id={lastOffers[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        lastOffers[0]?.offer_price,
                        lastOffers[0]?.offer_duration,
                        lastOffers[0]?.offer_description_02,
                        lastOffers[0]?.offer_description_01,
                        lastOffers[0]?.offer_description_03,
                        lastOffers[0]?.offer_description_04)}
                    
                    >Inscription</button>
                </div>
                </div>
                </div>
            ) : ("")}



{extraOffers.length > 0 ? (
                <div className="card-premium">
                <div className="card-header header-extra">
                <h1>{extraOffers[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{extraOffers[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-premium">
                    <ul className="card-element-container">
                    <li className="card-element">{extraOffers[0]?.offer_duration}</li>
                    <li className="card-element">{extraOffers[0]?.offer_description_01}</li>
                    <li className="card-element">{extraOffers[0]?.offer_description_02}</li>
                    <li className="card-element">{extraOffers[0]?.offer_description_03}</li>
                    <li className="card-element">{extraOffers[0]?.offer_description_04}</li>
                    <li className="card-element">{extraOffers[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-extra" 
                    id={extraOffers[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        extraOffers[0]?.offer_price,
                        extraOffers[0]?.offer_duration,
                        extraOffers[0]?.offer_description_02,
                        extraOffers[0]?.offer_description_01,
                        extraOffers[0]?.offer_description_03,
                        extraOffers[0]?.offer_description_04)}
                    
                    >Inscription</button>
                </div>
                </div>
                </div>
            ) : ("")}

        {anotherOffer.length > 0 ? (
                <div className="card-premium">
                <div className="card-header header-extra">
                <h1>{anotherOffer[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{anotherOffer[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-premium">
                    <ul className="card-element-container">
                    <li className="card-element">{anotherOffer[0]?.offer_duration}</li>
                    <li className="card-element">{anotherOffer[0]?.offer_description_01}</li>
                    <li className="card-element">{anotherOffer[0]?.offer_description_02}</li>
                    <li className="card-element">{anotherOffer[0]?.offer_description_03}</li>
                    <li className="card-element">{anotherOffer[0]?.offer_description_04}</li>
                    <li className="card-element">{anotherOffer[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-extra" 
                    id={anotherOffer[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        anotherOffer[0]?.offer_price,
                        anotherOffer[0]?.offer_duration,
                        anotherOffer[0]?.offer_description_02,
                        anotherOffer[0]?.offer_description_01,
                        anotherOffer[0]?.offer_description_03,
                        anotherOffer[0]?.offer_description_04)}
                    
                    >Inscription</button>
                </div>
                </div>
                </div>
            ) : ("")}


    {exceedOffers.length > 0 ? (
                <div className="card-premium">
                <div className="card-header header-extra">
                <h1>{exceedOffers[0]?.offer_title}</h1>
                </div>
                <div className="card-body">
                <p><h2>{exceedOffers[0]?.offer_price}</h2></p>
                <div className="card-element-hidden-premium">
                    <ul className="card-element-container">
                    <li className="card-element">{exceedOffers[0]?.offer_duration}</li>
                    <li className="card-element">{exceedOffers[0]?.offer_description_01}</li>
                    <li className="card-element">{exceedOffers[0]?.offer_description_02}</li>
                    <li className="card-element">{exceedOffers[0]?.offer_description_03}</li>
                    <li className="card-element">{exceedOffers[0]?.offer_description_04}</li>
                    <li className="card-element">{exceedOffers[0]?.offer_description_05}</li>
                    </ul>
                    <button className="btn-price btn-extra" 
                    id={exceedOffers[0]?.offer_title}
                    onClick={(e) => handleOffersNavigations(e, 
                        exceedOffers[0]?.offer_price,
                        exceedOffers[0]?.offer_duration,
                        exceedOffers[0]?.offer_description_02,
                        exceedOffers[0]?.offer_description_01,
                        exceedOffers[0]?.offer_description_03,
                        exceedOffers[0]?.offer_description_04)}
                    
                    >Inscription</button>
                </div>
                </div>
                </div>
            ) : ("")}

           
            </main>
        </>
    )
}




export default OfferPreview