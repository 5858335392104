import React from 'react';
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useAuth } from './AuthContext';
import "../css/admin.css"



const Admin = (props) => {
  const { authenticated, setAuthenticated, authenticatedAdmin, setAuthenticatedAdmin, login, loginAdmin } = useAuth();
  const navigate = useNavigate()
  const [myUser, setUser] = useState({
    admin_user: "",
    admin_password: "",
    admin_role: ""
  });

  const [myAdmin, setAdmin] = useState({
    admin_user: "",
    admin_password: "",
    admin_role: "",
    admin_tel: "",
    admin_email: ""
  });


  const [yes_admin, setStatus] = useState(true);

  const token = localStorage.getItem("user_id");
  const headers = {
          Authorization: `Bearer ${token}`,
      };

  useEffect(() => {
    const handleCheckAdmin = async () => {
      try {
        const checkIfAdmin = await axios.get(`${process.env.REACT_APP_HOST}is_admin`);
        if (checkIfAdmin.status === 200) {
          console.log("Goood Morning Admin !")
          setStatus(true);
        }
      } catch (err) {
        console.log("Goood Night Admin !")
        setStatus(false);
      }
    };

    handleCheckAdmin();
  }, []);



  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST}check`, myUser);
      // console.log('User response:', response);
  
      if (response.status === 200) {
        localStorage.clear();
        const userRole = myUser.admin_role;
        const userName = myUser.admin_user;
  
        if (userRole !== "owner") {
          login(response.data.token);
          console.log('User authenticated successfully!');
          localStorage.setItem('userRole', userRole);
          localStorage.setItem('userName', userName);
          // console.log('Navigating to:', `/admin/${userRole}/${userName}`);
          window.history.replaceState({}, document.title, window.location.href);
          navigate(`/admin/${userRole}/${userName}`);
        } else {
          loginAdmin(response.data.token);
          console.log('Owner authenticated successfully!');
          localStorage.setItem('userRole', userRole);
          // console.log('Navigating to:', `/admin/${userRole}`);
          window.history.replaceState({}, document.title, window.location.href);
          navigate(`/admin/${userRole}`);
        }
      }
    } catch (err) {
      console.error('Login error:', err);
      // Handle login error (e.g., show a message to the user)
    }
  };
  


  const handleChange = (e) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
    // console.log(myUser)
  }

  const handleChangeAdmin = (e) => {
    setAdmin(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleNewAdmin = async (e) => {
    e.preventDefault()
    try {
      const createAdminQuery = await axios.post(`${process.env.REACT_APP_HOST}admin` ,myAdmin)
      if (createAdminQuery.status === 200) {
        console.log("Admin created Successfully !")
        const myInterval = setTimeout(async () => {
          window.location.reload()
          await clearTimeout(myInterval)

        }, 1000)

      }
    } catch (err) {
      if (err) console.log(err)
    }
  }
  return (
    <main class="admin-auth" id="check-admin">
      <form class="admin-form" autocomplete="false">
        <h1>Accéder au Gestionnaire</h1>
        {yes_admin ? (
          <>
            <input onChange={handleChange} type="text" name="admin_user" id="" placeholder="Utilisateur:" required="" />
            <input onChange={handleChange} type="password" name="admin_password" id="" placeholder="Mot de passe:" required="" />
            <select onChange={handleChange} id="roles" name="admin_role">
              <option value="null" name="admin_role">Connecter Comme: </option>
              <option value="owner" name="admin_role">Owner</option>
              <option value="assistant" name="admin_role">Assistant</option>
              <option value="technical" name="admin_role">Technical</option>
              <option value="teacher" name="admin_role">Teacher</option>
            </select>
          </>

        ) : (
          <>
            <input onChange={handleChangeAdmin} type="email" name="admin_email" id="" placeholder="Email:" required="" />
            <input onChange={handleChangeAdmin} type="text" name="admin_user" id="" placeholder="Utilisateur:" required="" />
            <br /><br /> <br />
            <input onChange={handleChangeAdmin} type="password" name="admin_password" id="" placeholder="Mot de passe:" required="" />
            <input onChange={handleChangeAdmin} type="text" name="admin_tel" id="" placeholder="Tel:" required="" />
            <select onChange={handleChangeAdmin} id="roles" name="admin_role">
              <option value="null" name="admin_role">Connecter Comme: </option>
              <option value="owner" name="admin_role">Owner</option>
            </select>
          </>

        )}

        {yes_admin ? (
          <button class="checkAdmin" onClick={handleClick}>Accéder</button>
        ) : (
          <button class="checkAdmin" onClick={handleNewAdmin}>Create Admin</button>
        )}

      </form>
    </main>
  )
}


export default Admin