import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Don't forget to import axios if it's not imported
import { useLocation } from 'react-router-dom';
import "../css/exams.css";
import {useNavigate} from "react-router-dom"

const Exams = (props) => {
    const [allExams, setAllExams] = useState([]);
    const token = localStorage.getItem("userId_2");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const navigate = useNavigate()

    const location = useLocation();
    const { subject } = location.state || {};

    useEffect(() => {
        const handleExams = async () => {
            try {
                const fetchAllExams = await axios.get(`${process.env.REACT_APP_HOST}all_exams/${subject}`, { headers });
                setAllExams(fetchAllExams.data);
                console.log(fetchAllExams.data);
            } catch (err) {
                console.error(err);
            }
        };

        handleExams();
    }, [subject]); // Add props.subject as a dependency

    return (
        <div className="exams-container">
              <button onClick={() => navigate('/auth')} className="back-button">Retour</button>
            <h1 className="exams-title">Exams List</h1>
            <ul className="exams-list">
                {allExams.length > 0 ? (
                    allExams.map((exam, index) => (
                        <li key={index} className="exam-item">
                            <h3 className="exam-title">{exam.subtitle}</h3>
                            <a href={exam.exam_link} target='_blank' rel="noopener noreferrer">
                                <button className="exam-button">Voir</button>
                            </a>
                        </li>
                    ))
                ) : (
                    <p className="no-exams-message">No exams available.</p>
                )}
            </ul>
        </div>
    );
}

export default Exams;
