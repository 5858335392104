




import React, { useRef, useState, useEffect } from "react";
import Owner from "./Owner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/subjects.css";

const Subjects = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [titles, setAllTitles] = useState([]);
  const form = useRef();
  const pdfForm = useRef();
  const coursForm = useRef();
  const examForm = useRef();
  const newLesson = useRef();
  const oldLesson = useRef();
  const oldButton = useRef();
  const newButton = useRef(); 

//   #################



const newLessonPDF = useRef();
const oldLessonPDF = useRef();
const oldButtonPDF = useRef();
const newButtonPDF = useRef();


// ###########################

const newLessonCours = useRef();
const oldLessonCours = useRef();
const oldButtonCours = useRef();
const newButtonCours = useRef();


// ###############################

const newLessonExam = useRef();
const oldLessonExam = useRef();
const oldButtonExam = useRef();
const newButtonExam = useRef();

// ################################
  const myForm = useRef();
  const token = localStorage.getItem("userId");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Fetch all subjects
  useEffect(() => {
    const getSubjects = async () => {
      try {
        const allSubjects = await axios.get(`${process.env.REACT_APP_HOST}subjects`, { headers });
        setSubjects(allSubjects.data);
      } catch (err) {
        console.error("Error fetching subjects", err);
      }
    };
    getSubjects();
  }, []);

  // Display lesson setup form
  const showSetup = async (e) => {
    e.preventDefault();
    const subjectName = e.target.className.trim();
    form.current.style.setProperty("display", "flex");
    form.current.id = subjectName; // set current subject ID to form for reference
    
    try {
      const table = subjectName.toLowerCase();
      const allTitles = await axios.get(`${process.env.REACT_APP_HOST}subjects/ready/${table}`, { headers });
      setAllTitles(allTitles.data);
    } catch (err) {
      console.error("Error fetching titles:", err);
    }
  };

   // Display lesson setup form
   const showSetupPDF = async (e) => {
    e.preventDefault();
    const subjectName = e.target.className.trim();
    pdfForm.current.style.setProperty("display", "flex");
    pdfForm.current.id = subjectName; // set current subject ID to form for reference
    
    try {
      const table = subjectName.toLowerCase();
      const allTitles = await axios.get(`${process.env.REACT_APP_HOST}subjects/ready/${table}`, { headers });
      setAllTitles(allTitles.data);
    } catch (err) {
      console.error("Error fetching titles:", err);
    }
  };


    // Display lesson setup form
    const showSetupCours = async (e) => {
        e.preventDefault();
        const subjectName = e.target.className.trim();
        coursForm.current.style.setProperty("display", "flex");
        coursForm.current.id = subjectName; // set current subject ID to form for reference
        
        try {
          const table = subjectName.toLowerCase();
          const allTitles = await axios.get(`${process.env.REACT_APP_HOST}subjects/ready/${table}`, { headers });
          setAllTitles(allTitles.data);
        } catch (err) {
          console.error("Error fetching titles:", err);
        }
      };


       // Display lesson setup form
    const showSetupExams = async (e) => {
        e.preventDefault();
        const subjectName = e.target.className.trim();
        examForm.current.style.setProperty("display", "flex");
        examForm.current.id = subjectName; // set current subject ID to form for reference
        
        try {
          const table = subjectName.toLowerCase();
          const allTitles = await axios.get(`${process.env.REACT_APP_HOST}subjects/ready/${table}`, { headers });
          setAllTitles(allTitles.data);
        } catch (err) {
          console.error("Error fetching titles:", err);
        }
      };

  // Close form handler
  const handleCloseLesson = (e) => {
    e.preventDefault();
    form.current.style.setProperty("display", "none");
  };

  // Close form handler
  const handleClosePdf = (e) => {
    e.preventDefault();
    pdfForm.current.style.setProperty("display", "none");
  };

  const handleCloseCours = (e) => {
    e.preventDefault();
    coursForm.current.style.setProperty("display", "none");
  };

  const handleCloseExams = (e) => {
    e.preventDefault();
    examForm.current.style.setProperty("display", "none");
  };

  // Add new lesson handler
  const handleLessonAdd = async (e) => {
    e.preventDefault();

    const lessonName = e.target[1].value !== "" ? e.target[1].value : e.target[0].value;
    const sublessonName = e.target[4].value;
    const sublessonVideoLink = e.target[5].value;
    
    const formData = {
      title: lessonName.toLowerCase(),
      subtitle: sublessonName.toLowerCase(),
      video_link: sublessonVideoLink,
      reference: form.current.id,
      inherited_from: lessonName.toLowerCase(),
    };

    try {
      await axios.post(`${process.env.REACT_APP_HOST}api/subjects/${form.current.id}`, formData, { headers });
      form.current.style.setProperty("display", "none");
      myForm.current.reset();
    } catch (error) {
      console.error("Error adding lesson:", error);
    }
  };



  // Add new pdf handler
  const handlePdfAdd = async (e) => {
    e.preventDefault();

    const lessonName = e.target[1].value !== "" ? e.target[1].value : e.target[0].value;
    const sublessonName = e.target[4].value;
    const sublessonPdfLink = e.target[5].value;
    const sublessonPdfVideo = e.target[6].value;
    console.log(sublessonPdfVideo)
    
    const formPDFData = {
      title: lessonName.toLowerCase(),
      subtitle: sublessonName.toLowerCase(),
      pdf_link: sublessonPdfLink,
      reference: pdfForm.current.id,
      inherited_from: lessonName.toLowerCase(),
      exercice_video_link: sublessonPdfVideo
    };

    try {
      await axios.post(`${process.env.REACT_APP_HOST}pdfs/${pdfForm.current.id}`, formPDFData, { headers });
      pdfForm.current.style.setProperty("display", "none");
      pdfForm.current.reset();
    } catch (error) {
      console.error("Error adding lesson:", error);
    }
  };



  // Add new cours handler
  const handleCoursAdd = async (e) => {
    e.preventDefault();

    const lessonName = e.target[1].value !== "" ? e.target[1].value : e.target[0].value;
    const sublessonName = e.target[4].value;
    const sublessonCoursLink = e.target[5].value;
    
    const formCoursData = {
      title: lessonName.toLowerCase(),
      subtitle: sublessonName.toLowerCase(),
      course_link: sublessonCoursLink,
      reference: coursForm.current.id,
      inherited_from: lessonName.toLowerCase(),
    };

    try {
      await axios.post(`${process.env.REACT_APP_HOST}cours/${coursForm.current.id}`, formCoursData, { headers });
      coursForm.current.style.setProperty("display", "none");
      coursForm.current.reset();
    } catch (error) {
      console.error("Error adding lesson:", error);
    }
  };



   // Add new exam handler
   const handleExamAdd = async (e) => {
    e.preventDefault();

    const lessonName = e.target[1].value !== "" ? e.target[1].value : e.target[0].value;
    const sublessonName = e.target[4].value;
    const sublessonExamLink = e.target[5].value;
    
    const formExamData = {
      title: lessonName.toLowerCase(),
      subtitle: sublessonName.toLowerCase(),
      exam_link: sublessonExamLink,
      reference: coursForm.current.id,
      inherited_from: lessonName.toLowerCase(),
    };

    try {
      await axios.post(`${process.env.REACT_APP_HOST}exams/${examForm.current.id}`, formExamData, { headers });
      examForm.current.style.setProperty("display", "none");
      examForm.current.reset();
    } catch (error) {
      console.error("Error adding lesson:", error);
    }
  };





  // Toggle between new and old lesson
  const handleOldLesson = (e) => {
    e.preventDefault();
    oldLesson.current.style.display = "none";
    newLesson.current.style.display = "flex";
    oldButton.current.style.display = "block";
    newButton.current.style.display = "none";
  };

  const handleNewLesson = (e) => {
    e.preventDefault();
    oldLesson.current.style.display = "flex";
    newLesson.current.style.display = "none";
    oldButton.current.style.display = "none";
    newButton.current.style.display = "block";
  };
//  #####################################

   // Toggle between new and old lesson
   const handleOldLessonPDF = (e) => {
    e.preventDefault();
    oldLessonPDF.current.style.display = "none";
    newLessonPDF.current.style.display = "flex";
    oldButtonPDF.current.style.display = "block";
    newButtonPDF.current.style.display = "none";
  };

  const handleNewLessonPDF = (e) => {
    e.preventDefault();
    oldLessonPDF.current.style.display = "flex";
    newLessonPDF.current.style.display = "none";
    oldButtonPDF.current.style.display = "none";
    newButtonPDF.current.style.display = "block";
  };
//   #######################################
     // Toggle between new and old lesson
     const handleOldLessonCours = (e) => {
        e.preventDefault();
        oldLessonCours.current.style.display = "none";
        newLessonCours.current.style.display = "flex";
        oldButtonCours.current.style.display = "block";
        newButtonCours.current.style.display = "none";
      };
    
      const handleNewLessonCours = (e) => {
        e.preventDefault();
        oldLessonCours.current.style.display = "flex";
        newLessonCours.current.style.display = "none";
        oldButtonCours.current.style.display = "none";
        newButtonCours.current.style.display = "block";
      };
    //  ############################################

       // Toggle between new and old lesson
   const handleOldLessonExam = (e) => {
    e.preventDefault();
    oldLessonExam.current.style.display = "none";
    newLessonExam.current.style.display = "flex";
    oldButtonExam.current.style.display = "block";
    newButtonExam.current.style.display = "none";
  };

  const handleNewLessonExam = (e) => {
    e.preventDefault();
    oldLessonExam.current.style.display = "flex";
    newLessonExam.current.style.display = "none";
    oldButtonExam.current.style.display = "none";
    newButtonExam.current.style.display = "block";
  };

// #######################################################


  return (
    <>
      <Owner />
      <div className="grand-subjects-container">
        <div className="subjects-container">
          {subjects.length > 0 ? (
            subjects.map((subject) => (
              <div key={subject.id}>
                <h1>{subject.subject_name}</h1>
                <p>{subject.subject_level}</p>
                <button
                  id={subject.id}
                  className={subject.subject_name ? subject.subject_name.replace(/\s+/g, "") : "default-class"}
                  onClick={showSetup}
                >
                  Ajouter Leçon
                </button>
                <button
                  id={subject.id}
                  className={subject.subject_name ? subject.subject_name.replace(/\s+/g, "") : "default-class"}
                  onClick={showSetupPDF}
                >
                  Ajouter PDF
                </button>
                <button
                  id={subject.id}
                  className={subject.subject_name ? subject.subject_name.replace(/\s+/g, "") : "default-class"}
                  onClick={showSetupCours}
                >
                  Ajouter Cours
                </button>

                <button
                  id={subject.id}
                  className={subject.subject_name ? subject.subject_name.replace(/\s+/g, "") : "default-class"}
                  onClick={showSetupExams}
                >
                 Ajouter Examen
                </button>
              </div>
            ))
          ) : (
            <p>les matiéres n'éxistent pas</p>
          )}
        </div>
      </div>

      <div className="setup-subject" ref={form}>
        <form className="setup-form" onSubmit={handleLessonAdd} ref={myForm}>
          <select ref={oldLesson} className="lessons-show">
            <option value="" selected>
              Choisir:
            </option>
            {titles.map((title) => (
              <option value={title.title}>{title.title}</option>
            ))}
          </select>
          <input ref={newLesson} className="newLesson" type="text" placeholder="Lesson Name:" />
          <button ref={newButton} onClick={handleOldLesson} className="newButton">
          Nouvelle leçon
          </button>
          <button ref={oldButton} onClick={handleNewLesson} className="oldButton">
           Leçon de la liste
          </button>
          <input type="text" placeholder="Sublesson Name:" />
          <input type="text" placeholder="SubLesson Video Link:" />
          <button>Ajouter Leçon</button>
          <button onClick={handleCloseLesson}>Annuler</button>
        </form>
      </div>


      <div className="setup-subject" ref={pdfForm}>
        <form className="setup-form" onSubmit={handlePdfAdd} ref={pdfForm}>
          <select ref={oldLessonPDF} className="lessons-show">
            <option value="" selected>
              Choisir:
            </option>
            {titles.map((title) => (
              <option value={title.title}>{title.title}</option>
            ))}
          </select>
          <input ref={newLessonPDF} className="newLesson" type="text" placeholder="Lesson Name:" />
          <button ref={newButtonPDF} onClick={handleOldLessonPDF} className="newButton">
            Nouvelle leçon
          </button>
          <button ref={oldButtonPDF} onClick={handleNewLessonPDF} className="oldButton">
           Leçon de la liste
          </button>
          <input type="text" placeholder="Sublesson Name:" />
          <input type="text" placeholder="SubLesson PDF Exercice Link:" />
          <input type="text" placeholder="SubLesson PDF Video Link:" />
          <button>Ajouter PDF</button>
          <button onClick={handleClosePdf}>Annuler</button>
        </form>
      </div>




      <div className="setup-subject" ref={coursForm}>
        <form className="setup-form" onSubmit={handleCoursAdd} ref={coursForm}>
          <select ref={oldLessonCours} className="lessons-show">
            <option value="" selected>
              Choisir:
            </option>
            {titles.map((title) => (
              <option value={title.title}>{title.title}</option>
            ))}
          </select>
          <input ref={newLessonCours} className="newLesson" type="text" placeholder="Lesson Name:" />
          <button ref={newButtonCours} onClick={handleOldLessonCours} className="newButton">
          Nouvelle leçon
          </button>
          <button ref={oldButtonCours} onClick={handleNewLessonCours} className="oldButton">
          Leçon de la liste
          </button>
          <input type="text" placeholder="Sublesson Name:" />
          <input type="text" placeholder="SubLesson PDF Cours Link:" />
          <button>Ajouter Cours</button>
          <button onClick={handleCloseCours}>Annuler</button>
        </form>
      </div>

      <div className="setup-subject" ref={examForm}>
        <form className="setup-form" onSubmit={handleExamAdd} ref={examForm}>
          <select ref={oldLessonExam} className="lessons-show">
            <option value="" selected>
              Choisir:
            </option>
            {titles.map((title) => (
              <option value={title.title}>{title.title}</option>
            ))}
          </select>
          <input ref={newLessonExam} className="newLesson" type="text" placeholder="Lesson Name:" />
          <button ref={newButtonExam} onClick={handleOldLessonExam} className="newButton">
          Nouvelle leçon
          </button>
          <button ref={oldButtonExam} onClick={handleNewLessonExam} className="oldButton">
          Leçon de la liste
          </button>
          <input type="text" placeholder="Sublesson Name:" />
          <input type="text" placeholder="SubLesson PDF Exam Link:" />
          <button>َAjouter Examen</button>
          <button onClick={handleCloseExams}>Annuler</button>
        </form>
      </div>
    </>
  );
};

export default Subjects;
