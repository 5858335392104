
import axios from 'axios';

export const lineChartData = {
    labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ],

    datasets: [
        {
            label: "Users",
            data: [3000, 5000, 4500, 6000, 8000, 7000, 9000],
            borderColor: "rgb(75,192,192)",
        },
    ],
};








// try {
//     const videos = await axios.get("http://localhost:5000/parents/verified")
// } catch (err) {
//     if (err) console.log(err)
// }

// try {
//     const teachers = await axios.get("http://localhost:5000/parents/verified")
// } catch (err) {
//     if (err) console.log(err)
// }


export const getChartData = async () => {
    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    try {
        const activeUsers = await axios.get(`${process.env.REACT_APP_HOST}parents/verified`, {headers});
        const newUsers = await axios.get(`${process.env.REACT_APP_HOST}parents/verified`, {headers});
        const subjects = await axios.get(`${process.env.REACT_APP_HOST}subjects`, {headers});
        const courses = await axios.get(`${process.env.REACT_APP_HOST}courses`, {headers});

        return {
            activeUsers: activeUsers.data.length,
            newUsers: newUsers.data.length,
            subjects: subjects.data.length,
            courses: courses.data.length,
        };
    } catch (err) {
        console.error(err);
        return {};
    }
};

export const barChartData = async () => {
    const chartData = await getChartData();

    return {
        labels: ["STATISTICS"],
        datasets: [
            {
                label: "Active Users",
                data: [chartData.activeUsers || 0],
                backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                borderColor: ["rgba(255, 99, 132, 1)"],
                borderWidth: 1,
            },
            {
                label: "New Users",
                data: [chartData.newUsers || 0],
                backgroundColor: ["rgba(54, 162, 235, 0.2)"],
                borderColor: ["rgba(54, 162, 235, 1)"],
                borderWidth: 1,
            },
            {
                label: "Subjects",
                data: [chartData.subjects || 0],
                backgroundColor: ["rgba(24, 100, 44, 0.2)"],
                borderColor: ["rgba(24, 100, 44, 1)"],
                borderWidth: 1,
            },
            {
                label: "Videos",
                data: [100], // Example data for videos
                backgroundColor: ["rgba(54, 42, 86, 0.2)"],
                borderColor: ["rgba(54, 42, 86, 1)"],
                borderWidth: 1,
            },
            {
                label: "Courses",
                data: [chartData.courses || 0],
                backgroundColor: ["rgba(75, 192, 192, 0.2)"],
                borderColor: ["rgba(75, 192, 192, 1)"],
                borderWidth: 1,
            },
            {
                label: "Teachers",
                data: [60], // Example data for teachers
                backgroundColor: ["rgba(99, 145, 212, 0.2)"],
                borderColor: ["rgba(99, 145, 212, 1)"],
                borderWidth: 1,
            },
        ],
    };
};
