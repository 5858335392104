




import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/messages.css";
import Owner from "./Owner";

const Students = (props) => {
    const [messages, setMessages] = useState([]);

    // Function to block a user
    const handleBlock = async (e) => {
        e.preventDefault();
        const confirmVerify = window.confirm("Do You Want To Block This User?");
        if (confirmVerify) {
            try {
                const token = localStorage.getItem("userId");
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                await axios.put(
                    `${process.env.REACT_APP_HOST}parents/block/${e.target.id}`,
                    {},
                    { headers }
                );
                // Update the state to remove the blocked user
                setMessages((prevMessages) =>
                    prevMessages.filter((message) => message.id !== parseInt(e.target.id))
                );
            } catch (err) {
                console.error("Error blocking user:", err);
                alert("Failed to block the user. Please try again later.");
            }
        }
    };

    // Function to load messages (user data)
    useEffect(() => {
        const loadMessages = async () => {
            try {
                const token = localStorage.getItem("userId");
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const loadData = await axios.get(
                    `${process.env.REACT_APP_HOST}parents/verified`,
                    { headers }
                );
                setMessages(loadData.data);
            } catch (err) {
                console.error("Error loading data:", err);
                alert("Failed to load data. Please try again later.");
            }
        };
        loadMessages();
    }, []);

    return (
        <React.Fragment>
            <Owner />
            <table className="messages-table">
                <thead>
                    <tr className="messages-heads">
                        <th>Id</th>
                        <th>FullName</th>
                        <th>UserName</th>
                        <th>Age</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Level</th>
                        <th>Option</th>
                        <th>Status</th>
                        <th>Blocker</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => (
                        <tr key={message.id} className="messages-rows">
                            <td className="id-field">{message.id}</td>
                            <td>{message.fullname}</td>
                            <td>{message.username}</td>
                            <td>{message.age} Years</td>
                            <td>{message.email}</td>
                            <td>{message.phone}</td>
                            <td>{message.city}</td>
                            <td>{message.level}</td>
                            <td>{message.option_field}</td>
                            <td>Validated</td>
                            <td>
                                <button id={message.id} className="block-user" onClick={handleBlock}>
                                    Blocker
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default Students;
