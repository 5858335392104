import React from 'react';

const UnauthorizedAccess = (props) => {
    // Redirect to login or show a message
    // You can use the Navigate component to redirect the user to the login page
    // or display a message indicating that they need to log in.
    // Example:
    // const navigate = useNavigate();
    // navigate('/login');
    return <div>Unauthorized access. Please log in.</div>;
  };
  

  export default UnauthorizedAccess