import { React, useState, useEffect, useRef } from 'react'

import axios from "axios"

import { Link, useNavigate } from "react-router-dom"


import "../css/about.css"


// import defLogo from "../images/logo/transparen_logo.png"
import defLogo from "../images/svg/logo_v5.svg"








const About = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_HOST}api/about/send-email`, formData);
      alert('Your message has been sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred while sending your message. Please try again later.');
    }
  };
  return (
    <div className="about-container">
      <section className="intro">
        <img src={defLogo} alt="Econo-Edge Logo" height={150} />
        <h1 className='about-page'>À propos d' Extra-Stud</h1>
        <p>Extra-Stud est une plateforme éducative numérique innovante qui offre aux étudiants des ressources interactives et personnalisées pour améliorer leur apprentissage dans divers domaines académiques.</p>
      </section>

      <section className="offers">
        <h2 className='about-page'>Nos Offres</h2>
        <div className="cards">
          <div className="card">
            <h3 className='about-page'>Leçons Pédagogiques</h3>
            <p>Des leçons détaillées et compréhensibles pour vous aider dans vos études.</p>
          </div>
          <div className="card">
            <h3 className='about-page'>Cours Professionnels</h3>
            <p>100% conforme avec un cadre ministériel.</p>
          </div>
          <div className="card">
            <h3 className='about-page'>Offres</h3>
            <p>Nombreuses offres pour aider l'élève à choisir la bonne offre.</p>
          </div>
        </div>
      </section>
{/* 
      <section className="admin">
        <h2 className='about-page'>Administrateur</h2>
        <div className="admin-card">
       
          <h3 className='about-page'>Gnaoui Abdellah</h3>
          <p>Gnaoui Abdellah est le responsable de la gestion et de l'administration de la plateforme Extra-Stud. Avec plusieurs années d'expérience dans le domaine de l'éducation, il s'assure que la plateforme répond aux besoins des étudiants et des professionnels.</p>
        </div>
      </section> */}

     

    </div>
  );
};

export default About;