





import { AuthProvider, useAuth } from './pages/AuthContext';
import { Auth_Provider, useAuthLogin } from './pages/AuthUser';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import "./css/manage-subjects.css"
import "./css/app.css"
import { useParams, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Home_ar from './pages/Home-ar';
import Parental from './pages/Parent';
import Admin from './pages/Admin';
import Owner from './pages/Owner';
import Members from './pages/Members';
import Messages from './pages/Messages';
import Teacher from './pages/Teacher';
import Technical from './pages/Technical';
import Assistant from './pages/Assistant';
import NotFound from './components/notFound';
import UnauthorizedAccess from './pages/UnauthorizedAccess';
import Students from "./pages/Student"
import Settings from "./pages/Settings"
import Courses from "./pages/Courses"
import Subjects from "./pages/Subjects"
import ManSubjects from "./pages/ManSubjects"
import ManCourses from "./pages/ManCourses"
import Thank from "./pages/Thank"
import Lessons from "./pages/Lessons"
import Dashboard from "./pages/Dashboard"
import SecretDash from "./pages/SecretDash"
import Login from "./pages/Login"
import Login_ar from "./pages/Login-ar"
import Auth from "./pages/Auth"
import { NewCourse, NewLevel, NewOption } from "./pages/ContentManagement"
import About from "./pages/About"
import Hi_offer from './pages/Hioffer';
import OfferPreview from './pages/OfferPreview';
import Reports from "./pages/Reports"
import Contact from "./pages/Contact"
import Reservation from "./pages/Reservation"
import PlayerPlayList from "./pages/PlayerPlayList"
import AllLessons from './pages/AllLessons';
import Rules from "./pages/SetupRules"
import Dev from "./pages/Dev"
import Exams from "./pages/Exams"


function App() {
  return (
    <AuthProvider>
      <Auth_Provider>
        <AppContent />
      </Auth_Provider>
    </AuthProvider>
  );
}

function AppContent() {
  const navigate = useNavigate()
  const { authenticatedAdmin, authenticated } = useAuth();
  const role = localStorage.getItem("userRole");
  const user = localStorage.getItem("userName");
  // console.log(process.env.HOST)
  const AdminDashboardPaths = authenticatedAdmin && !authenticated ? "/admin/owner" : authenticated && !authenticatedAdmin ? `/admin/${role}/${user}` : "/admin/forum";
  if (user && authenticated) {
    localStorage.setItem("onlineUser", `/admin/${role}/${user}`)
  }
  if (!user && authenticatedAdmin) {
    localStorage.setItem("onlineUser", `/admin/${role}`)
  }


  useEffect(() => {
    // console.log(AdminDashboardPaths);
    window.localStorage.setItem("ACCESS_TOKEN_SECRET", process.env.REACT_APP_ACCESS_TOKEN_SECRET)
    // console.log(process.env.REACT_APP_ACCESS_TOKEN_SECRET)
    // Your other useEffect logic here
  }, [authenticatedAdmin, authenticated, role, user]);

  // useEffect(() => {
  //   if((window.location.pathname === "/admin/forum" && !authenticated) || (window.location.pathname === "/admin/forum" && !authenticatedAdmin)){
  //     navigate("/")
  //   }
  // }, [authenticatedAdmin, authenticated, role, user]);
  const prefer_lang = window.localStorage.getItem("pref_lang")
  return (
    <Routes>
      <Route path="/" element={prefer_lang === "ع" ? < Home_ar /> : < Home />} />
      <Route path='/about' element={<About />} />
      <Route path="/who/info" element={<About />} />
      <Route path="/auth/:subject" element={<ProtectedRoute />} />
      <Route path={AdminDashboardPaths} element={<AdminDashboardComponents />} />
      <Route path="/admin/forum" element={<Admin />} />
      <Route path="/login" element={prefer_lang === "ع" ? < Login_ar /> : < Login />} />
      <Route path='/auth/*' element={<AuthConditional />} />
      <Route path='/access/courses' element={<PlayerPlayList />} />
      <Route path='/rv-prof' element={<Reservation />} />
      <Route path="/auth/*" element={<WelcomeConditions />} />
      <Route path="/unauthenticated" element={<UnauthorizedAccess />} />
      <Route path="/admin/owner" element={<OwnerConditional />} />
      <Route path="/admin/:role/:user" element={<AdminConditional />} />
      <Route path="/admin/owner/members" element={<MembersConditional />} />
      <Route path="/admin/owner/messages" element={<MessagesConditional />} />
      <Route path="/admin/owner/students" element={<StudentsConditional />} />
      <Route path='/admin/owner/settings' element={<SettingsConditional />} />
      <Route path='/admin/owner/dashboard' element={<DashboardConditional />} />
      <Route path='/admin/owner/reports' element={<ReportsConditional />} />
      <Route path='/admin/owner/settings/online/subjects' element={<SubjectsConditional />} />
      <Route path='/admin/owner/settings/online/courses' element={<CoursesConditional />} />
      <Route path='/admin/owner/settings/manage/subjects' element={<ManSubjConditional />} />
      <Route path='/admin/owner/settings/manage/courses' element={<ManCourConditional />} />
      <Route path='/admin/owner/settings/new/course' element={<CourseContent />} />
      <Route path='/admin/owner/settings/new/level' element={<LevelContent />} />
      <Route path='/admin/owner/settings/new/option' element={<OptionContent />} />
      <Route path='/rules' element={<RulesConditions/>} />
      <Route path='/developper' element={<Dev/>} />
      <Route path='/auth/all-exams' element={<Exams/>} />
      <Route path="/contact/parental" element={<Parental />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/inscription" element={<Parental />} />
      <Route path="/secret_dash/parental" element={<SecretDashConditional />} />
      <Route path="/redirect/thanks" element={<Thank />} />
      <Route path="/show/video" element={<Lessons />} />
      <Route path="/preview/offer" element={<Hi_offer />} />
      <Route path="/suggested_offers" element={<OfferPreview />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const AdminConditional = () => {
  const { authenticated } = useAuth();
  const { role, user } = useParams();

  if (role === 'teacher' && authenticated && localStorage.getItem("userRole") === "teacher" && localStorage.getItem("userName") === user) {
    return <Teacher />;
  } else if (role === 'technical' && authenticated && localStorage.getItem("userRole") === "technical" && localStorage.getItem("userName") === user) {
    return <Technical />;
  } else if (role === 'assistant' && authenticated && localStorage.getItem("userRole") === "assistant" && localStorage.getItem("userName") === user) {
    return <Assistant />;
  } else if (role === "owner") {
    return <Owner />;
  } else {

    return (
      <div>{`Unauthorized access. Authenticated: ${authenticated}`}</div>
    );

  }
};

const MembersConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Members />;
  } else {
    return (
      <Admin />
    );
  }
}






const SubjectsConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Subjects />;
  } else {
    return (
      <Admin />
    );
  }
}


const CoursesConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Courses />;
  } else {
    return (
      <Admin />
    );
  }
}


const DashboardConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Dashboard />;
  } else {
    return (
      <Admin />
    );
  }
}


const ReportsConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Reports />;
  } else {
    return (
      <Admin />
    );
  }
}

const ManCourConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <ManCourses />;
  } else {
    return (
      <Admin />
    );
  }
}

const ManSubjConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <ManSubjects />;
  } else {
    return (
      <Admin />
    );
  }
}


const RulesConditions = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Rules />;
  } else {
    return (
      <Admin />
    );
  }
}


const CourseContent = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <NewCourse />;
  } else {
    return (
      <Admin />
    );
  }
}

const LevelContent = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <NewLevel />;
  } else {
    return (
      <Admin />
    );
  }
}


const OptionContent = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <NewOption />;
  } else {
    return (
      <Admin />
    );
  }
}




const MessagesConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Messages />;
  } else {
    return (
      <Admin />
    );
  }
}

const StudentsConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Students />;
  } else {
    return (
      <Admin />
    );
  }
}


const SettingsConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Settings />;
  } else {
    return (
      <Admin />
    );
  }
}


const OwnerConditional = () => {
  const { authenticatedAdmin } = useAuth();

  if (authenticatedAdmin) {
    return <Owner />;
  } else {

    return (
      <Admin />
    );


  }
}

const AdminDashboardComponents = () => {
  const { authenticatedAdmin, authenticated } = useAuth();

  if (authenticatedAdmin) {
    return <Owner />;
  } else if (authenticated && !authenticatedAdmin) {

    const role = localStorage.getItem("userRole")
    if (role === "technical") {
      return <Technical />
    }

    if (role === "assistant") {
      return <Assistant />
    }

    if (role === "teacher") {
      return <Teacher />
    }

  } else {
    return <Admin />
  }
}




const SecretDashConditional = () => {
  const { authenticated_user } = useAuthLogin();

  if (authenticated_user) {
    return <SecretDash />;
  } else {
    return (
      <div>{`Unauthorized access Admin. Authenticated: ${authenticated_user}`}</div>
    );
  }
}






const AuthConditional = () => {
  const { authenticated_user } = useAuthLogin();
  const prefer_lang = window.localStorage.getItem("pref_lang")

  if (authenticated_user) {
    return <Auth />;
  } else if (!authenticated_user && prefer_lang === "ع") {
    return <Login_ar />;
  } else if (!authenticated_user && prefer_lang === "Fr") {
    return <Login />;
  }else if(!authenticated_user){
    return <Login />;
  }


}

const ProtectedRoute = () => {
  const { authenticated_user } = useAuthLogin();
  // const prefer_lang = window.localStorage.getItem("pref_lang")

  if (authenticated_user) {
    return <AllLessons />;
  } else if (!authenticated_user) {
    return <Login />;
  }else{
    return <Login />;
  }


}

const WelcomeConditions = () => {
  const { authenticated_user } = useAuthLogin();
  const prefer_lang = window.localStorage.getItem("pref_lang")
  if (authenticated_user) {
    return <Auth />;
  } else if (!authenticated_user && prefer_lang === "ع") {
    return <Login_ar />;
  } else if (!authenticated_user && prefer_lang === "Fr") {
    return <Login />;
  }


}

export default App;











