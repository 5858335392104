import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../css/dashboard.css"
import Owner from "./Owner"
import axios, { all } from "axios"


const Dashboard = props => {

    const element = useRef()
    const students = useRef()
    const messages = useRef()
    const teachers = useRef()
    const assistants = useRef()
    const technicals = useRef()
    const subjects = useRef()
    const courses = useRef()
    const videos = useRef()
    const blocked = useRef()





    useEffect(() => {
        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const options = { weekday: 'long' };
        const dayName = date.toLocaleDateString('en-US', options);
        const time = `${dayName}\n ${date.getFullYear()}/${date.getMonth()}/${date.getDate()}     --${hours}:${minutes}:${seconds}`;

        element.current.textContent = time;
        const token = localStorage.getItem("userId");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const loadBlockeds = async () => {
            try {
                const loadData = await axios.get(`${process.env.REACT_APP_HOST}parents/blocked`, {headers});
                blocked.current.textContent = `${loadData.data.length}`
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ");
            }
        };

        const loadMessages = async (e) => {
            try {
                const loadData = await axios.get(`${process.env.REACT_APP_HOST}parents`, {headers})
                messages.current.textContent = loadData.data.length
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ")
            }

        }

        const loadStudents = async (e) => {
            try {
                const loadData = await axios.get(`${process.env.REACT_APP_HOST}parents/verified`, {headers})
                students.current.textContent = loadData.data.length
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ")
            }

        }

        const loadSubjects = async (e) => {
            try {
                const allSubjects = await axios.get(`${process.env.REACT_APP_HOST}subjects`, {headers})
                subjects.current.textContent = allSubjects.data.length
            } catch (err) {
                console.error({ error: err })
            }
        }

        const loadCourses = async (e) => {
            try {
                const allCourses = await axios.get(`${process.env.REACT_APP_HOST}courses`, {headers})
                courses.current.textContent = allCourses.data.length

            } catch (err) {
                console.error({ error: err })
            }
        }

        const loadMembers = async () => {
            try {
                const fetchUsers = await axios.get(`${process.env.REACT_APP_HOST}members`, {headers})

                const uniqueAssistants = [];
                const uniqueTechnicals = [];
                const uniqueTeachers = [];
                // console.log("Data Coming From Server: ", fetchUsers.data)

                fetchUsers.data.forEach((user) => {
                    if (user.admin_role === "assistant" && !uniqueAssistants.some((a) => a.admin_user === user.admin_user)) {
                        uniqueAssistants.push(user);
                    }

                    if (user.admin_role === "technical" && !uniqueTechnicals.some((t) => t.admin_user === user.admin_user)) {
                        uniqueTechnicals.push(user);
                    }

                    if (user.admin_role === "teacher" && !uniqueTeachers.some((t) => t.admin_user === user.admin_user)) {
                        uniqueTeachers.push(user);
                    }
                });
                teachers.current.textContent = uniqueTeachers.length
                technicals.current.textContent = uniqueTechnicals.length
                assistants.current.textContent = uniqueAssistants.length
            } catch (err) {
                console.log(err)
            }
        }


        loadMessages()
        loadBlockeds()
        loadStudents()
        loadSubjects()
        loadCourses()
        loadMembers()
    }, []);






    return (
        <>
            <Owner />
            <h1 ref={element} className="dashboard"></h1>
            <div className="dashboard-container">
                <div>Blocked Students <h3 ref={blocked} className="interface"></h3></div>
                <div>Students <h3 ref={students} className="interface"></h3></div>
                <div>Messages <h3 ref={messages} className="interface"></h3></div>
                <div>Teachers <h3 ref={teachers} className="interface"></h3></div>
                <div>Assistants <h3 ref={assistants} className="interface"></h3></div>
                <div>Technicals <h3 ref={technicals} className="interface"></h3></div>
                <div>Subjects <h3 ref={subjects} className="interface"></h3></div>
                <div>Courses <h3 ref={courses} className="interface"></h3></div>
                <div>Videos <h3 ref={videos} className="interface"></h3></div>
            </div>
        </>
    )
}


export default Dashboard