





import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/messages.css";
import Owner from "../pages/Owner";
import validateIcon from "../images/icons/veified-user.png";

const Messages = () => {
    const [messages, setMessages] = useState([]);
    const [subjects, setSubjects] = useState([]); // To store the list of subjects
    const [selectedSubjects, setSelectedSubjects] = useState([]); // To store selected subjects in the form
    const [currentUserId, setCurrentUserId] = useState(null); // To track the user id for editing subjects
    const [showForm, setShowForm] = useState(false); // Control form visibility

    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Fetch messages (users) on component mount
    useEffect(() => {
        const loadMessages = async () => {
            try {
                const loadData = await axios.get(`${process.env.REACT_APP_HOST}parents`, { headers });
                if (loadData.status === 200 && Array.isArray(loadData.data)) {
                    setMessages(loadData.data);
                }
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ");
            }
        };

        loadMessages();
    }, []);

    // Fetch available subjects from the endpoint
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST}subjects`, { headers });
                if (response.status === 200) {
                    setSubjects(response.data);
                }
            } catch (err) {
                console.error("Error fetching subjects:", err);
            }
        };

        fetchSubjects();
    }, []);

    // Handle the submission of the subjects form
    const handleSubjectsSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(
                `${process.env.REACT_APP_HOST}setnewrules`,
                {
                    userId: currentUserId,
                    including_subjects: selectedSubjects
                },
                { headers }
            );
            setShowForm(false);
            alert("Subjects updated successfully!");
        } catch (err) {
            console.error("Error updating subjects:", err);
        }
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        const confirmVerify = window.confirm("Do You Want To Verify This User?");
        if (confirmVerify) {
            try {
                const id = e.target.id;
                const changeVerification = await axios.put(`${process.env.REACT_APP_HOST}parents/${id}`, {}, { headers });

                if (changeVerification.status === 200) {
                    // Update the specific user's verification status without reloading
                    setMessages(prevMessages =>
                        prevMessages.map(message =>
                            message.id === parseInt(id) ? { ...message, verified: true } : message
                        )
                    );
                    console.log("User verified successfully!");
                }
            } catch (err) {
                console.log(new Error(err), "Server Axios Error: ");
            }
        }
    };

    // Show the subject selection form
    const handleSubjectsOffer = (userId) => {
        setCurrentUserId(userId);
        setShowForm(true);
    };

    return (
        <React.Fragment>
            <Owner />
            <table className="messages-table">
                <thead>
                    <tr className="messages-heads">
                        <td>Id</td>
                        <td>FullName</td>
                        <td>UserName</td>
                        <td>Age</td>
                        <td>Email</td>
                        <td>Phone</td>
                        <td>City</td>
                        <td>Level</td>
                        <td>Option</td>
                        <td>Offer</td>
                        <td>Validation</td>
                        <td>Custom</td>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => (
                        <tr key={message.id} className="messages-rows">
                            <td className="id-field">{message.id}</td>
                            <td>{message.fullname}</td>
                            <td>{message.username}</td>
                            <td>{message.age} Years</td>
                            <td>{message.email}</td>
                            <td>{message.phone}</td>
                            <td>{message.city}</td>
                            <td>{message.level}</td>
                            <td>{message.option_field}</td>
                            <td>{message.offer_field}</td>
                            <td>
                                <button id={message.id} onClick={handleVerification} className="validMessage">
                                    Valider <img src={validateIcon} alt="Green Validate Icon" height={23} />
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => handleSubjectsOffer(message.id)}
                                    className="editOffer"
                                >
                                    Custom <img src={validateIcon} alt="Green Validate Icon" height={23} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Form to edit subjects */}
            {showForm && (
                <div className="subject-form">
                    <h3>Edit Subjects for User ID: {currentUserId}</h3>
                    <form onSubmit={handleSubjectsSubmit}>
                        <label>Select Subjects:</label>
                        <select
                            multiple
                            value={selectedSubjects}
                            onChange={(e) =>
                                setSelectedSubjects(
                                    Array.from(e.target.selectedOptions, option => option.value)
                                )
                            }
                        >
                            {subjects.map((subject) => (
                                <option key={subject.id} value={subject.subject_name}>
                                    {subject.subject_name}
                                </option>
                            ))}
                        </select>
                        <button type="submit" className="updateed">Update Subjects</button>
                        <button type="button" className="messageeee" onClick={() => setShowForm(false)}>
                            Cancel
                        </button>
                    </form>
                </div>
            )}
        </React.Fragment>
    );
};

export default Messages;

