import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "../css/parental_form.css"





const Parental = (props) => {
  const navigate = useNavigate()
  const [student, setStudent] = useState({
    fullname: "",
    username: "",
    age: "",
    email: "",
    phone: "",
    password: "",
    city: "",
    level: "",
    option_field: "",
    offer_field: "",
  })
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const [Levels, setLevels] = useState([])
  const [MyCourses, setCourses] = useState([])
  const [Options, setOptions] = useState([])
  const [offers, setOffers] = useState([])
  useEffect(() => {


    const submit_data_func = async (e) => {

      try {


        const forLevels = await axios.get(`${process.env.REACT_APP_HOST}new/levels`)
        const forOptions = await axios.get(`${process.env.REACT_APP_HOST}new/options`)
        const forCourses = await axios.get(`${process.env.REACT_APP_HOST}new/courses`)
        const forOffers = await axios.get(`${process.env.REACT_APP_HOST}app/active_offers`)
        if (forLevels.status === 200) {
          setLevels(forLevels.data)
        }

        if (forOptions.status === 200) {
          setOptions(forOptions.data)
        }

        if (forCourses.status === 200) {
          setCourses(forCourses.data)
        }

        if (forOffers.status === 200) {
          setOffers(forOffers.data)
        }

      } catch (err) {
        if (err) {
          console.log(err)
        }
      }
    }
    submit_data_func()
  }, [])



  const handleAddStudent = async (e) => {
    e.preventDefault()
    try {
      const postOperation = await axios.post(`${process.env.REACT_APP_HOST}parents`, student)

      if (postOperation.status === 200) {

        let myError = document.querySelector("div.myMessageContainer")
        if (myError) {
          myError.remove()
        }
        setTimeout(() => {
          navigate("/redirect/thanks")
        }, 1200)
      } else {

        let myError = document.querySelector("div.myMessageContainer")
        if (myError) {
          return null;
        } else {
          let myMessageContainer = document.createElement("div")
          myMessageContainer.className = "myMessageContainer"
          let myMessageElement = document.createElement("h1")
          let message = "There was a problem Try Again Please"
          myMessageElement.textContent = message
          myMessageContainer.appendChild(myMessageElement)
          myMessageContainer.style.cssText = "position:absolute;top:0;display: flex; align-items: center; align-content: center; justify-content: center;left: 50%;margin-left: -30%;width: 60%;"
          myMessageElement.style.cssText = "display: flex;align-items: center;align-content: center; justify-content: center;text-align: center; font-weight: bolder; font-family: cursive; color: red; font-size: 30px;"
          document.body.appendChild(myMessageContainer)
        }
      }
    } catch (err) {
      if (err) {
        let myError = document.querySelector("div.myMessageContainer")
        if (myError) {
          return null;
        } else {
          let myMessageContainer = document.createElement("div")
          myMessageContainer.className = "myMessageContainer"
          let myMessageElement = document.createElement("h1")
          // let message = "There was a problem Try Again Please"
          let message = err.response.data.message || "There was a problem Try Again Please"
          myMessageElement.textContent = message
          myMessageContainer.appendChild(myMessageElement)
          myMessageContainer.style.cssText = "position:absolute;top:0;display: flex; align-items: center; align-content: center; justify-content: center;left: 50%;margin-left: -30%;width: 60%;"
          myMessageElement.style.cssText = "display: flex;align-items: center;align-content: center; justify-content: center;text-align: center; font-weight: bolder; font-family: cursive; color: red; font-size: 30px;"
          document.body.appendChild(myMessageContainer)
        }

      }
    }
  }




  const handleChange = (e) => {
    setStudent(prev => ({ ...prev, [e.target.name]: e.target.value }))
    // console.log(student)
  }

  const handleRedirection = (e) => {
    if (window.location.hash !== "#opened") {
      const opened_window = window.open('/suggested_offers', "_blank");
      window.location.hash = "opened"
    } else {
      console.log("Not Opened")
    }

  }

  const handleCombinedChange = (event) => {
    handleChange(event);
    handleRedirection(event);
  }

  return (
    <React.Fragment>

      <div className="parent">
        <h1 className="parent">S'inscrire aux EXTRA-STUD</h1>
      </div>
      <form id="myForm" className="parent" required>
        <div className="form-group">
          <label htmlFor="fullname" className="parent">Nome Complet:</label>
          <input type="text" id="fullname" name="fullname" required className="parent" onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="username" className="parent">Nom d'utilisateur:</label>
          <input type="text" id="username" name="username" required className="parent" onChange={handleChange} placeholder="Choose Unique Username: " />
        </div>
        <div className="form-group">
          <label htmlFor="age" className="parent">Age</label>
          <input type="number" id="age" name="age" min="15" max="99" required className="parent" onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="parent">Email</label>
          <input type="email" id="email" name="email" required className="parent" onChange={handleChange} placeholder="ex: example@example.com" />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="parent">Numéro du tel:</label>
          <input type="tel" id="phone" name="phone" required className="parent" onChange={handleChange} placeholder="ex: 06XXXXXXXX" />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="parent">Mot de Passe:</label>
          <input type="password" id="password" name="password" required className="parent" onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="city" className="parent">Ville</label>
          <select id="city" name="city" required className="parent" onChange={handleChange}>
            <option value="" selected>Choisit une ville: </option>
            <option value="Casablanca" name="city">Casablanca</option>
            <option value="Rabat" name="city">Rabat</option>
            <option value="Marrakech" name="city">Marrakech</option>
            <option value="Fes" name="city">Fes</option>
            <option value="Agadir" name="city">Agadir</option>
            <option value="Tangier" name="city">Tangier</option>
            <option value="Meknes" name="city">Meknes</option>
            <option value="Oujda" name="city">Oujda</option>
            <option value="Kenitra" name="city">Kenitra</option>
            <option value="Tetouan" name="city">Tetouan</option>
            <option value="Safi" name="city">Safi</option>
            <option value="Mohammedia" name="city">Mohammedia</option>
            <option value="Khouribga" name="city">Khouribga</option>
            <option value="Beni Mellal" name="city">Beni Mellal</option>
            <option value="El Jadida" name="city">El Jadida</option>
            <option value="Taza" name="city">Taza</option>
            <option value="Nador" name="city">Nador</option>
            <option value="Settat" name="city">Settat</option>
            <option value="Berrechid" name="city">Berrechid</option>
            <option value="Tanger-Assilah" name="city">Tanger-Assilah</option>
            <option value="Guelmim" name="city">Guelmim</option>
            <option value="Errachidia" name="city">Errachidia</option>
            <option value="Larache" name="city">Larache</option>
            <option value="Khenifra" name="city">Khenifra</option>
            <option value="Taroudant" name="city">Taroudant</option>
            <option value="Essaouira" name="city">Essaouira</option>
            <option value="AlHoceima" name="city">AlHoceima</option>
            <option value="Ouarzazate" name="city">Ouarzazate</option>
            <option value="Sidi Slimane" name="city">Sidi Slimane</option>
            <option value="Tifelt" name="city">Tifelt</option>
            <option value="Zagora" name="city">Zagora</option>
            <option value="Rissani" name="city">Rissani</option>
            <option value="Arfoud" name="city">Arfoud</option>
            <option value="Berkane" name="city">Berkane</option>
            <option value="Tadla" name="city">Tadla</option>
            <option value="Chafchaoun" name="city">Chafchaoun</option>
          </select>

        </div>
        <div className="form-group">
          <label htmlFor="levels" className="parent">Niveaux</label>
          <select id="levels" name="level" required className="parent" onChange={handleChange}>
            <option value="">Selectionner niveau scolaire:</option>
            {/* <option value="TCB" name="level">TCB</option>
            <option value="1 BAC" name="level">1 BAC</option>
            <option value="2 BAC" name="level">2 BAC</option>
            <option value="FAC" name="level">FAC</option>
            <option value="ESGI" name="level">ESGI</option> */}

            {Levels.map((level) => (
              <option key={level.id} value={level.title} name="level">{level.title}</option>

            ))}
            <option value="Autre" name="level">Autre</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="option_field" className="parent">Options</label>
          <select id="option_field" name="option_field" required className="parent" onChange={handleChange}>
            <option value="">Selectionner un option: </option>
            {/* <option value="Tronc Commun Biof" name="option_field">Tronc Commun Biof</option>
            <option value="1BAC --SGC" name="option_field">1BAC --SGC</option>
            <option value="1BAC --ECO" name="option_field">1BAC --ECO</option>
            <option value="1BAC --SM" name="option_field">1BAC --SM</option>
            <option value="1BAC --EXP" name="option_field">1BAC --EXP</option>
            <option value="2BAC --SGC" name="option_field">2BAC --SGC</option>
            <option value="2BAC --ECO" name="option_field">2BAC --ECO</option>
            <option value="2BAC --PC" name="option_field">2BAC --PC</option>
            <option value="2BAC --SVT" name="option_field">2BAC --SVT</option>
            <option value="2BAC --SM-A" name="option_field">2BAC --SM-A</option>
            <option value="2BAC --SM-B" name="option_field">2BAC --SM-B</option>
            <option value="Faculté ECO" name="option_field">Faculté ECO</option>
            <option value="Faculté SGC" name="option_field">Faculté SGC</option>
            <option value="Formation" name="option_field">Formation</option> */}
            {Options.map((option) => (
              <option key={option.id} value={option.title} name="option_field">{option.title}</option>

            ))}


            <optgroup id="option_field" name="option_field" required className="parent" onChange={handleChange}>
              <option value="">Selectionner une formation: </option>
              {MyCourses.map((course) => (
                <option key={course.id} value={course.title} name="option_field">{course.title}</option>

              ))}
            </optgroup>

          </select>
          <label htmlFor="offer_field" className="parent">Offres:</label>
          <select onChange={handleCombinedChange} id="offer_field" name="offer_field" required className="parent">
            <option selected value="" >Selectionner une offre: </option>
            {offers.map((offer) => (
              <option value={offer?.offer_title} name="offer_field">{offer?.offer_title}</option>
            ))}
          </select>
        </div>
        <button className="btn-form" onClick={handleAddStudent}>S'inscrire</button>
      </form>

      <div className="main">
  <div className="footer">
    <div className="bubbles">
      {[...Array(128)].map((_, i) => (
        <div
          key={i}
          className="bubble"
          style={{
            '--size': `${2 + (i % 5) * 0.5}rem`, // Bubbles get slightly larger every 5 elements
            '--distance': `${6 + i * 2}rem`, // Distance increases consistently
            '--position': `${(i * 5) % 100}%`, // Position is evenly distributed across the width
            '--time': `${10 + (i % 10) * 2}s`, // Time varies in a predictable way
            '--delay': `${-1 * (i % 6)}s`, // Delay follows a systematic repeating pattern
          }}
        ></div>
      ))}
    </div>
  



          <a className="image" href="https://codepen.io/z-" target="_blank" style={{ backgroundImage: `url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/happy.svg')` }}></a>
          <p>©2024 </p>
        </div>
      </div>

    </React.Fragment >
  )
}

export default Parental