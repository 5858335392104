import { React, useEffect, useState, useRef } from "react"

import { Link, useNavigate } from "react-router-dom"

import Owner from "./Owner"

import "../css/reports.css"

import { Bar } from 'react-chartjs-2';

import { lineChartData, barChartData } from "./FAKE_DATA";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js"


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)
const Reports = () => {
    const options = {};

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const data = await barChartData();
            setChartData(data);
        };

        fetchData();
    }, []);

    return (
        <>
            <Owner />
            <div className="barChart">
                {chartData.datasets && (
                    <Bar options={options} data={chartData} />
                )}
            </div>
        </>
    );
};

export default Reports;