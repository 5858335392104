







import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/welcome.css';
import userIcon from '../images/icons/user_blue.png';
import { useAuthLogin } from './AuthUser.js';
import { useNavigate } from 'react-router-dom';
import physique from "../images/subjects/pc.svg";
import svt from "../images/subjects/svt.svg";
import math from "../images/subjects/math.svg";
import anglais from "../images/subjects/english.svg";
import eco from "../images/subjects/eco1.svg";
import statis from "../images/subjects/stats.svg";
import orga from "../images/subjects/orga.svg";
import eco2 from "../images/subjects/eco2.svg";
import philo from "../images/subjects/philo.png";
import ar from "../images/subjects/ar.jpg";
import fr from "../images/subjects/fr.png";
import islam from "../images/subjects/islam.png";
import histoire from "../images/subjects/geo.png";

const Auth = () => {
    const [userOption, setOption] = useState("");
    const [userOffer, setOffer] = useState("");
    const [userId, setId] = useState("");
    const [userSubjects, setSubjects] = useState([]);
    const { logout } = useAuthLogin();
    const navigate = useNavigate();

    // Map subject keywords to their corresponding images
    const subjectImages = {
        "math": math,
        "svt": svt,
        "physique": physique,
        "anglais": anglais,
        "eco": eco,
        "statis": statis,
        "orga": orga,
        "eco2": eco2,
        "philo": philo,
        "fr": fr,
        "arabe": ar,
        "islam": islam,
        "histoire": histoire
        // Add more mappings as needed
    };
    const token = localStorage.getItem("userId_2");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const getResUser = await axios.get(`${process.env.REACT_APP_HOST}parents/verified_spec/${window.localStorage.getItem("active-user")}`, {headers});
                if (getResUser.status === 200) {
                    setOption(getResUser.data[0].option_field);
                    setOffer(getResUser.data[0].offer_field);
                    setId(getResUser.data[0].id);
                    setSubjects(JSON.parse(getResUser.data[0].including_subjects));
                    // console.log(JSON.parse(getResUser.data[0].including_subjects))
                //     try {
                //         const getResSubj = await axios.get(`http://localhost:5000/subjects/show/${getResUser.data[0].option_field}`);
                //         if (getResSubj.status === 200) {
                //             setSubjects(getResSubj.data);
                //         }
                //     } catch (err) {
                //         console.log(err);
                //     }
                }
            } catch (err) {
                console.log(err);
            }
        };
        getUser();
    }, []);

    const handleSubjectClick = (subjectName) => {
        navigate(`/auth/${subjectName.toLowerCase().replace(/\s+/g, '')}`);
    };

    const handleLogOut = (e) => {
        e.preventDefault();
        window.localStorage.clear();
        logout();
    };

    const getSubjectImage = (subjectName) => {
        const lowerCaseName = subjectName.toLowerCase();
        for (const key in subjectImages) {
            if (lowerCaseName.includes(key)) {
                return subjectImages[key];
            }
        }
        return null;
    };

    return (
        <div className="auth-container">
            <header className="header-auth">
                <div className="user-info">
                    <img src={userIcon} alt="User Icon" className="user-icon" />
                    <h1 className="username">{window.localStorage.getItem('active-user')}</h1>
                    <button className="logout-button" onClick={handleLogOut}>Déconnexion</button>
                    <div className="user-details">
                        <span className="detail">Option: <span>{userOption}</span></span>
                        {/* <span className="detail">Offer: <span>{userOffer}</span></span> */}
                    </div>
                </div>
            </header>

            <main className="main-content">
                <div className="greeting">
                    <h1>Bienvenue, <span>{window.localStorage.getItem('active-user')}</span></h1>
                </div>

                <div className="ccontent">
                    <section className="subjects">
                        <h2>Matiéres</h2>
                        <div className="ssubjects-container">
                            {userSubjects.map((subj) => {
                                const subjectImage = getSubjectImage(subj);

                                return (
                                    <div key={subj.subject_name} className="subject-item-container" onClick={() => handleSubjectClick(subj)}>
                                        {subjectImage && <img className="subj-img" src={subjectImage} alt={subj.subject_name} height={180} />}
                                        <div className="subject-item">
                                            {subj}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default Auth;






