import React from 'react';
import "../css/Home.css"
import { useState, useEffect, useRef } from "react"
import search from "../images/icons/search.png"
import showMore from "../images/icons/cat-arrow.png"
import global_logo from "../images/logo/logo.jpg"
// import global_logo_transparent from "../images/logo/transparen_logo.png"
import { Link, useNavigate } from 'react-router-dom';
import vec01 from "../images/vectors/vector01.png"
import welcomeArrow from "../images/svg/arrow-vector.svg"
import dark from '../images/icons/dark-mode.png';
import light from '../images/icons/light-mode.png';
import global_logo_transparent from "../images/svg/LOGO_v3.svg"
// import global_logo_transparent from "../images/svg/LOGO_v3.svg"
import setting_expander from '../images/icons/show-settings.png';
import firstAnim from "../images/gifs/animation01.gif"
import starIcon from "../images/icons/star.png"
import why01 from "../images/svg/why01.png"
import why02 from "../images/svg/why02.svg"
import why03 from "../images/svg/why03.svg"
// import vec02 from "../images/vectors/vector02.svg";
// import vec05 from "../images/svg/vector05.svg";
import introVid from "../videos/intro.mp4"
import introPoster from "../images/jpg/intro-poster.png"
import fa from "../images/social-media/facebook.svg";
import insta from "../images/social-media/instagram.svg"
import tik from "../images/social-media/tiktok.svg";
import what from "../images/social-media/whatsapp.svg";
import you from "../images/social-media/youtube.svg";
import { useAuth } from './AuthContext';
import { useAuthLogin } from './AuthUser';
import axios from "axios"
import user_icon from "../images/icons/user-icon.png"
import blue_pic from "../images/svg/blue_pic.png"
import vec02 from "../images/vectors/vector02.png";
import vec05 from "../images/svg/present.png";


const Home = (props) => {
    const navigate = useNavigate()
    const { authenticated_user } = useAuthLogin();
    const { authenticatedAdmin, authenticated } = useAuth();
    const onlineUser = localStorage.getItem("onlineUser")
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [offersDropdownOpen, setOffersDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleOffersDropdown = () => setOffersDropdownOpen(!offersDropdownOpen);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        // Get the saved theme from localStorage or default to light mode
        return localStorage.getItem('theme') === 'dark';
    });

    useEffect(() => {
        // Apply the theme when the component mounts
        if (darkMode) {
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.add('light-mode');
            document.body.classList.remove('dark-mode');
        }
    }, [darkMode]);

    const handleExpander = () => {
        setSettingsVisible(!settingsVisible);
    };


    const handleTranslate = (e) => {
        if (e.target.textContent === "ع") {
            window.localStorage.setItem("pref_lang", "ع")
            window.location.reload()
        } else if (e.target.textContent === "Fr") {
            window.localStorage.setItem("pref_lang", "Fr")
            window.location.reload()
        }
    }

    const toggleDarkMode = () => {
        setDarkMode(true);
        localStorage.setItem('theme', 'dark');
    };

    const toggleLightMode = () => {
        setDarkMode(false);
        localStorage.setItem('theme', 'light');
    };



    //Offers Setup
    const [firstOffers, setFirstOffers] = useState([{}])
    const [secondOffers, setSecondOffers] = useState([{}])
    const [thirdOffers, setThirdOffers] = useState([{}])
    const [lastOffers, setLastOffers] = useState([{}])

    const [extraOffers, setExtraOffers] = useState([{}])
    const [anotherOffer, setAnotherOffer] = useState([{}])
    const [exceedOffers, setExceedOffers] = useState([{}])


    useEffect(() => {
        const getOffers01 = async () => {
            try {
                const myfLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/1`)
                setFirstOffers(myfLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers02 = async () => {
            try {
                const mysLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/2`)
                setSecondOffers(mysLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers03 = async () => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/3`)
                setThirdOffers(mytLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }



        const getOffers04 = async () => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/4`)
                setLastOffers(mytLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers05 = async () => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/5`)
                setExtraOffers(mytLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers06 = async () => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/6`)
                setAnotherOffer(mytLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }

        const getOffers07 = async () => {
            try {
                const mytLinks = await axios.get(`${process.env.REACT_APP_HOST}app/offers/7`)
                setExceedOffers(mytLinks.data)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }
        getOffers01()
        getOffers02()
        getOffers03()
        getOffers04()
        getOffers05()
        getOffers06()
        getOffers07()
    }, [])


    const handleOffersNavigations = (e, price, duration, quality, content, functions, others) => {
        navigate(`/preview/offer/#${decodeURIComponent(e.target.id)}`);
        window.localStorage.setItem("offer_price", price);
        window.localStorage.setItem("offer_duration", duration);
        window.localStorage.setItem("offer_quality", quality);
        window.localStorage.setItem("offer_content", content);
        window.localStorage.setItem("offer_functions", functions);
        window.localStorage.setItem("offer_others", others);
    }


    const [videoUrl, setVideoUrl] = useState('');
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        const fetchVideo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST}getVideo/1`, {
                    responseType: 'blob', // Important to handle binary data
                });

                const videoBlob = new Blob([response.data], { type: response.headers['content-type'] });
                const videoUrl = URL.createObjectURL(videoBlob);
                setVideoUrl(videoUrl);
            } catch (error) {
                console.error('Error fetching video', error);
            }
        };

        fetchVideo();
    }, []);

    const handleOpenVideo = () => setShowVideo(true);
    const handleCloseVideo = () => setShowVideo(false);

    //Footer Setup
    const [socialLinks, setLinks] = useState([{}])
    useEffect(() => {
        const getLinks = async () => {
            try {
                const myLinks = await axios.get(`${process.env.REACT_APP_HOST}app/socialmedia`)
                setLinks(myLinks.data)
                console.log(socialLinks)
            } catch (err) {
                console.log("Internal Axios Error", err)
            }
        }
        getLinks()
    }, [])

    return (

        <React.Fragment>
            {/* Waves Effect */}
            <React.Fragment>

                <div className="headerAnime">
                    <div className="inner-header flexAnime">
                        <svg version="1.1" className="logoAnime" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" xmlSpace="preserve">
                            <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M57,283" />
                            <g>
                                <path fill="#fff" d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4 C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1 c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7 c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2 c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z" />
                            </g>
                        </svg>
                        <h1 style={{ fontFamily: "Allerta Stencil" }} className='education'>منصة تعليمية رقمية</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28"
                            preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </React.Fragment>

            {/* NavBar */}
            <React.Fragment>
                <div className='container-nav-bar'>
                    <nav className='navbar my-nv'>
                        <div className='logo-container'>
                            <div className='lang-bar' onClick={handleTranslate}>Fr</div>
                            <img
                                src={global_logo_transparent}
                                alt="Your Logo"
                                height={80}
                                className='first-logo'
                            />
                            <div className='title-of-app'>
                                {/* <h1 className='title-app'><span>E</span>xtra <span>S</span>tud</h1> */}
                            </div>
                        </div>

                        {authenticated_user && (
                            <div className='user-logo'>
                                <div className='user-logo-image'>
                                    <img src={user_icon} alt="user-icon" height={50} />
                                </div>
                                <h2 className='user-name-icon'>{window.localStorage.getItem("active-user").toUpperCase()}</h2>
                            </div>
                        )}

                        <div className='nav-container'>
                            {/* <div className='button dropdown' onClick={toggleOffersDropdown}>
                                العروض
                                <img src={showMore} alt="arrow-icon" height={15} className='arrow-icon' />
                                <div className={`dropdown-content ${offersDropdownOpen ? 'show' : ''}`}>
                                    <Link to="#ECO">باك اقتصاد</Link>
                                    <Link to="#SGC">باك ادارة</Link>
                                    <Link to="#Autre">أخرى</Link>
                                </div>
                            </div> */}
                            <Link className='button' to="/rv-prof">حجز مادة</Link>
                            <div className='button dropdown' onClick={toggleDropdown}>
                                لنبدأ
                                <img src={showMore} alt="arrow-icon" height={15} className='arrow-icon' />
                                <div className={`dropdown-inscri ${dropdownOpen ? 'show' : ''}`}>
                                    <Link to="/login">تسجيل الدخول</Link>
                                    <Link to="/inscription">تسجيل حساب</Link>
                                    {/* {authenticatedAdmin && !authenticated ? (
                                        <Link to="/admin/owner">الادارة</Link>
                                    ) : authenticated && !authenticatedAdmin ? (
                                        <Link to={`${onlineUser}`}>الادارة</Link>
                                    ) : (
                                        <Link to="/admin/forum">الادارة</Link>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </React.Fragment>


            {/* SlideShow */}
            <React.Fragment>
                <div className='container-slide-show'>
                    <img
                        src={blue_pic}
                        alt="Man hold Notebook"
                        height={430}
                    />

                    <img
                        src={welcomeArrow}
                        alt="Black Arrow"
                        className='welcome-arrow'
                        height={60}
                    />






                    <button className='btn  btn-lg start-btn' ><Link to={"/auth"}>لنبدأ</Link></button>
                    <div className='btn-watch-tr'>
                        <button className='watch-trailer' onClick={handleOpenVideo}>شاهد الفيديو</button>
                        {showVideo && (
                            <div className="video-popup-overlay">
                                <div className="video-popup-content">
                                    <button className="video-popup-close" onClick={handleCloseVideo}>×</button>
                                    <video controls autoPlay className="video-popup-video">
                                        <source src={videoUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>

            <React.Fragment>
                <div className='settings-container'>
                    <button className='settings-expander' onClick={handleExpander}>
                        <img src={setting_expander} alt="Settings" />
                    </button>
                    <div className={`settings-panel ${settingsVisible ? 'visible' : ''}`}>
                        <div className='settings-header'>
                            <h2>التفضيلات</h2>
                            <button className='close-btn' onClick={handleExpander}>×</button>
                        </div>
                        <div className='settings-content'>
                            <button className='theme-toggle' onClick={toggleDarkMode}>
                                <img src={dark} alt="Dark Mode" />
                                الوضع المظلم
                            </button>
                            <button className='theme-toggle' onClick={toggleLightMode}>
                                <img src={light} alt="Light Mode" />
                                الوضع النهاري
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>


            {/* FArticle */}

            <React.Fragment>
                <div className="f-article-container">
                    <div className='f-animation'>
                        <img src={firstAnim} alt="" />
                    </div>
                    <img src={starIcon} alt="" height={100} className='f-star' />
                    <div className='f-question'>
                        <h3 className=' question'>علاش  خصك تسجل معنا فالمنصة ؟</h3>
                    </div>

                    <div className="why-container">
                        <div>
                            <h4 className='home-page'>دروس و شروحات مختارة بعناية</h4>

                            <img src={why01} alt="" />

                        </div>
                        <div>

                        <h4 className='home-page'>محتوى تعليمي متميز بجودة عالية وشرح مبسط وفقًا للمرجع الوزاري</h4>
                            <hr />
                            <img src={why02} alt="" />

                        </div>
                        <div>
                            <h4 className='home-page'>اثمنة مناسبة و عروض عديدة</h4>

                            <img src={why03} alt="" />

                        </div>
                    </div>
                </div>

            </React.Fragment>

            {/* Courses */}

            <React.Fragment>
                <div className='courses-container'>
                    <div className='animated-vector'>
                        <img
                            src={vec02}
                            alt="vector03.svg"
                            height={400}
                            loading='lazy'
                        />
                        <h5>المحتوى</h5>
                    </div>
                    <div className='courses'>
                        <div><p className='top'>الاقتصاد</p></div>
                        <div><p className='top'>الادارة</p></div>
                        <div><p className='bottom'>الاحصائيات</p></div>
                        <div><p className='bottom'>تنظيم المقاولات</p></div>
                    </div>
                    {/* <div className='show-more'><button className='btn-show-more'>المزيد</button></div> */}
                </div>
            </React.Fragment>

            {/* Offers */}

            <React.Fragment>
                <div className='title-offers'>
                    <div>عروضنا الحصرية</div>
                </div>
                <div className="presenter-vector">
                    <img src={vec05} alt="Prenseter On Video Player Expressing Something !" />
                </div>
                <main className='of' id="offers">
                    {firstOffers.length > 0 ? (
                        <div className="card-basic">
                            <div className="card-header header-basic">
                                <h1>{firstOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{firstOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-basic">
                                    <ul className="card-element-container">
                                        <li className="card-element">{firstOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{firstOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{firstOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{firstOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{firstOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{firstOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-basic"
                                        id={firstOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            firstOffers[0]?.offer_price,
                                            firstOffers[0]?.offer_duration,
                                            firstOffers[0]?.offer_description_02,
                                            firstOffers[0]?.offer_description_01,
                                            firstOffers[0]?.offer_description_03,
                                            firstOffers[0]?.offer_description_04)}
                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}

                    {secondOffers.length > 0 ? (
                        <div className="card-standard">
                            <div className="card-header header-standard">
                                <h1>{secondOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{secondOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-standard">
                                    <ul className="card-element-container">
                                        <li className="card-element">{secondOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{secondOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{secondOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{secondOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{secondOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{secondOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-standard"
                                        id={secondOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            secondOffers[0]?.offer_price,
                                            secondOffers[0]?.offer_duration,
                                            secondOffers[0]?.offer_description_02,
                                            secondOffers[0]?.offer_description_01,
                                            secondOffers[0]?.offer_description_03,
                                            secondOffers[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}


                    {thirdOffers.length > 0 ? (
                        <div className="card-premium">
                            <div className="card-header header-premium">
                                <h1>{thirdOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{thirdOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-premium">
                                    <ul className="card-element-container">
                                        <li className="card-element">{thirdOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{thirdOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{thirdOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{thirdOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{thirdOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{thirdOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-premium"
                                        id={thirdOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            thirdOffers[0]?.offer_price,
                                            thirdOffers[0]?.offer_duration,
                                            thirdOffers[0]?.offer_description_02,
                                            thirdOffers[0]?.offer_description_01,
                                            thirdOffers[0]?.offer_description_03,
                                            thirdOffers[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>

                    ) : ("")}


                    {lastOffers.length > 0 ? (
                        <div className="card-premium">
                            <div className="card-header header-extra">
                                <h1>{lastOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{lastOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-premium">
                                    <ul className="card-element-container">
                                        <li className="card-element">{lastOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{lastOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{lastOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{lastOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{lastOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{lastOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-extra"
                                        id={lastOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            lastOffers[0]?.offer_price,
                                            lastOffers[0]?.offer_duration,
                                            lastOffers[0]?.offer_description_02,
                                            lastOffers[0]?.offer_description_01,
                                            lastOffers[0]?.offer_description_03,
                                            lastOffers[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}



                    {extraOffers.length > 0 ? (
                        <div className="card-premium">
                            <div className="card-header header-extra">
                                <h1>{extraOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{extraOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-premium">
                                    <ul className="card-element-container">
                                        <li className="card-element">{extraOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{extraOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{extraOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{extraOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{extraOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{extraOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-extra"
                                        id={extraOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            extraOffers[0]?.offer_price,
                                            extraOffers[0]?.offer_duration,
                                            extraOffers[0]?.offer_description_02,
                                            extraOffers[0]?.offer_description_01,
                                            extraOffers[0]?.offer_description_03,
                                            extraOffers[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}

                    {anotherOffer.length > 0 ? (
                        <div className="card-premium">
                            <div className="card-header header-extra">
                                <h1>{anotherOffer[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{anotherOffer[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-premium">
                                    <ul className="card-element-container">
                                        <li className="card-element">{anotherOffer[0]?.offer_duration}</li>
                                        <li className="card-element">{anotherOffer[0]?.offer_description_01}</li>
                                        <li className="card-element">{anotherOffer[0]?.offer_description_02}</li>
                                        <li className="card-element">{anotherOffer[0]?.offer_description_03}</li>
                                        <li className="card-element">{anotherOffer[0]?.offer_description_04}</li>
                                        <li className="card-element">{anotherOffer[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-extra"
                                        id={anotherOffer[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            anotherOffer[0]?.offer_price,
                                            anotherOffer[0]?.offer_duration,
                                            anotherOffer[0]?.offer_description_02,
                                            anotherOffer[0]?.offer_description_01,
                                            anotherOffer[0]?.offer_description_03,
                                            anotherOffer[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}


                    {exceedOffers.length > 0 ? (
                        <div className="card-premium">
                            <div className="card-header header-extra">
                                <h1>{exceedOffers[0]?.offer_title}</h1>
                            </div>
                            <div className="card-body">
                                <p><h2>{exceedOffers[0]?.offer_price}</h2></p>
                                <div className="card-element-hidden-premium">
                                    <ul className="card-element-container">
                                        <li className="card-element">{exceedOffers[0]?.offer_duration}</li>
                                        <li className="card-element">{exceedOffers[0]?.offer_description_01}</li>
                                        <li className="card-element">{exceedOffers[0]?.offer_description_02}</li>
                                        <li className="card-element">{exceedOffers[0]?.offer_description_03}</li>
                                        <li className="card-element">{exceedOffers[0]?.offer_description_04}</li>
                                        <li className="card-element">{exceedOffers[0]?.offer_description_05}</li>
                                    </ul>
                                    <button className="btn-price btn-extra"
                                        id={exceedOffers[0]?.offer_title}
                                        onClick={(e) => handleOffersNavigations(e,
                                            exceedOffers[0]?.offer_price,
                                            exceedOffers[0]?.offer_duration,
                                            exceedOffers[0]?.offer_description_02,
                                            exceedOffers[0]?.offer_description_01,
                                            exceedOffers[0]?.offer_description_03,
                                            exceedOffers[0]?.offer_description_04)}

                                    >اشتراك</button>
                                </div>
                            </div>
                        </div>
                    ) : ("")}


                </main>
            </React.Fragment>


            <React.Fragment>
                <div className='footer-container'>
                    <footer className='footer'>
                        <div className='social-media-icons'>
                            <ul>
                                <li><a target="_blank" href={socialLinks[0]?.social_link}><img src={what} height={50} alt="Whatsapp" /></a></li>
                                <li><a target="_blank" href={socialLinks[1]?.social_link}><img src={you} height={50} alt="Youtube" /></a></li>
                                <li><a target="_blank" href={socialLinks[2]?.social_link}><img src={insta} height={50} alt="Instagram" /></a></li>
                                <li><a target="_blank" href={socialLinks[3]?.social_link}><img src={fa} height={50} alt="Facebook" /></a></li>
                                <li><a target="_blank" href={socialLinks[4]?.social_link}><img src={tik} height={50} alt="Tiktok" /></a></li>
                            </ul>
                        </div>

                        <div className='link-acces'>
                            <ul>
                                <li><Link to="/inscription">انشاء حساب</Link></li>
                                <li><Link to="/developper">صفحة المطور</Link></li>
                                <li><Link to="/who/info">حول المنصة</Link></li>
                                <li><Link to="/contact">تواصل</Link></li>
                            </ul>
                        </div>

                        <div className='rights-production'>
                            <p>كل الحقوق محفوظة <span>&copy;</span> ل <span>EXTRA-STUD</span></p>
                        </div>
                    </footer>
                </div>
            </React.Fragment>




        </React.Fragment>
    )
}

export default Home