import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../css/login.css"
import axios from "axios"
import { useAuthLogin } from './AuthUser.js';




const Login = () => {
    const [user, setUser] = useState()
    const { login } = useAuthLogin();
    const message = useRef()
    const navigate = useNavigate()
    const checkAcess = async (e) => {
        e.preventDefault()
        try {

            const getUser = await axios.post(`${process.env.REACT_APP_HOST}login/newuser`, user)
            if (getUser.status === 200) {
                message.current.style.setProperty('color', "green", "important")
                message.current.innerHTML = getUser.data.message;
                localStorage.clear()
                login(getUser.data.token)
                window.localStorage.setItem("active-user", user.username)
                setTimeout(() => {
                    navigate("/auth")
                }, 1500)


            }
        } catch (error) {
            message.current.innerHTML = error.response.data;
        }

    }



    const handleChange = (e) => {
        setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    return (
        <>

            <div className="container-login-econo">
                <h1 ref={message} className="message-of-login"></h1>
                <div className="ring">
                    <i style={{ '--clr': '#00ff0a' }}></i>
                    <i style={{ '--clr': '#ff0057' }}></i>
                    <i style={{ '--clr': '#fffd44' }}></i>
                    <div className="login">
                        <h2 className="company-alert">تسجيل الدخول <br /> <span>Extra-Stud</span></h2>
                        <div className="inputBx">
                            <input type="text" placeholder="اسم المستخدم" name="username" onChange={handleChange} />
                        </div>
                        <div className="inputBx">
                            <input type="password" placeholder="كلمة السر" name="user_password" onChange={handleChange} />
                        </div>
                        <div className="inputBx">
                            <input type="submit" value="تسجيل الدخول" onClick={checkAcess} />
                        </div>
                        <div className="links">
                            <a href="/contact/parental">انشاء حساب</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Login