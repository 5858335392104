
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import "../css/subLessons.css";

const AllLessons = () => {
    const { subject } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [lessons, setLessons] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [view, setView] = useState('lessons');
    const [message, setMessage] = useState('');
    const [videos, setVideos] = useState([]);
    const [pdfs, setPDFs] = useState([]);
    const [cours, setCours] = useState([]);
    const [exams, setExams] = useState([]);
    const [playingVideo, setPlayingVideo] = useState(null);
    const [viewingPDF, setViewingPDF] = useState(null);
    const token = localStorage.getItem("userId_2");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const [selectedExam, setSelectedExam] = useState(null);

// Function to handle exam selection
const handleExamClick = (item) => {
    setSelectedExam(item); // Set the selected exam
  };
  



    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST}subjects/setup/${subject}`, {headers});
                if (response.status === 200) {
                    setLessons(response.data);
                }
            } catch (error) {
                console.error("Failed to fetch lessons:", error);
            }
        };

        fetchLessons();
    }, [subject]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lesson = params.get('lesson');
        const selection = params.get('selection');

        if (lesson) {
            setSelectedLesson(lesson);
            if (selection === 'videos') {
                fetchVideos(lesson);
                setView('videos');
            } else if (selection === 'exercices') {
                fetchPDF(lesson); // You already have this
                setView('pdfs');
            } else if (selection === 'cours') {
                fetchCours(lesson); // Added for cours
                setView('cours');
            } else if (selection === 'exams') {
                fetchPDF(lesson); // Added for exams
                setView('exams');
            } else if (selection) {
                setMessage(`Hello ${selection} will appear`);
                setView('message');
            } else {
                setView('lesson');
            }
        } else {
            setView('lessons');
        }
    }, [location.search]);

    const fetchVideos = async (lesson) => {
        try {
            const response = await axios.get( `${process.env.REACT_APP_HOST}subjects/filter/${subject}/${lesson}`, {headers});
            if (response.status === 200) {
                setVideos(response.data);
            }
        } catch (error) {
            console.error("Failed to fetch videos:", error);
        }
    };

    const fetchPDF = async (lesson) => {
        try {
            const response = await axios.get( `${process.env.REACT_APP_HOST}pdfs/filter/${subject}/${lesson}`, {headers});
            if (response.status === 200) {
                setPDFs(response.data);
            }
        } catch (error) {
            console.error("Failed to fetch PDFs:", error);
        }
    };

    const fetchCours = async (lesson) => {
        try {
            const response = await axios.get( `${process.env.REACT_APP_HOST}cours/filter/${subject}/${lesson}`, {headers});
            if (response.status === 200) {
                setCours(response.data);
                console.log(response.data)
            }
        } catch (error) {
            console.error("Failed to fetch cours:", error);
        }
    };

    // const fetchExams = async (lesson) => {
    //     try {
    //         const response = await axios.get( `${process.env.REACT_APP_HOST}exams/filter/${subject}/${lesson}`, {headers});
    //         if (response.status === 200) {
    //             setExams(response.data);
    //         }
    //     } catch (error) {
    //         console.error("Failed to fetch exams:", error);
    //     }
    // };

    const handleLessonClick = (lesson) => {
        setSelectedLesson(lesson);
        setView('lesson');

        // Navigate after setting the state
        setTimeout(() => {
            navigate(`?lesson=${lesson}`, { replace: true });
        }, 0);
    };

    const handleOptionClick = (selection) => {
        if (selection === 'videos') {
            fetchVideos(selectedLesson);
        } else if (selection === 'exercices') {
            fetchPDF(selectedLesson);
        } else if (selection === 'cours') {
            fetchCours(selectedLesson);
        } else if (selection === 'exams') {
            fetchPDF(selectedLesson);
        }

        setMessage(`Hello ${selection} will appear`);
        setView(
            selection === 'videos'
                ? 'videos'
                : (selection === 'exercices'
                    ? 'pdfs'
                    : (selection === 'cours'
                        ? 'cours'
                        : (selection === 'exams'
                            ? 'exams'
                            : 'message')))
        );

        // Navigate after setting the state
        setTimeout(() => {
            navigate(`?lesson=${selectedLesson}&selection=${selection}`, { replace: true });
        }, 0);
    };

    const handleVideoClick = (video) => {
        setPlayingVideo(video);
    };

    const handlePDFClick = (pdf) => {
        setViewingPDF(pdf);
    };

    const handleBackToPanel = () => {
        setMessage('');
        setView('lesson');

        setTimeout(() => {
            navigate(`?lesson=${selectedLesson}`, { replace: true });
        }, 0);
    };

    const handleBackToLessons = () => {
        setSelectedLesson(null);
        setMessage('');
        setView('lessons');

        setTimeout(() => {
            navigate('?', { replace: true });
        }, 0);
    };

   
    

    return (
        <div className="lessons-container">
            {view === 'lessons' && (
                <div>
                    <div className="header">
                        <h2 className="subject-title">{subject}</h2>
                        <button onClick={() => navigate('/auth')} className="back-button">Retour</button>
                        <div className='btn-to-exam'>
                        <button className='btn-show-exam' onClick={() => navigate('/auth/all-exams', { state: { subject } })}>Examens Nationaux</button>
                    </div>
                    </div>

                   

                    {lessons.length > 0 ? (
                        <div className="lesson-list">
                            {lessons.map((lesson) => (
                                <div
                                    key={lesson.id}
                                    className={`lesson-item ${selectedLesson === lesson.title ? 'selected' : ''}`}
                                    onClick={() => handleLessonClick(lesson.title)}
                                >
                                    <h4>{lesson.title}</h4>
                                    <button className="study-button">Commencer</button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="no-data">
                            <h1>No Data To Show</h1>
                        </div>
                    )}
                </div>
            )}

            {view === 'lesson' && (
                <div className="lesson-layout">
                    <button onClick={handleBackToLessons} className="back-to-lessons-button">Retour</button>
                    <div className="lesson-content">
                        <h3 className="lesson-title">Leçon: {selectedLesson}</h3>
                        <div className="lesson-options">
                            <div className="option-item" onClick={() => handleOptionClick("videos")}>
                                <div className="icon play-icon"></div>
                                <span className='auth_lesson'>Vidéos</span>
                            </div>
                            <div className="option-item" onClick={() => handleOptionClick("cours")}>
                                <div className="icon notes-icon"></div>
                                <span className='auth_lesson'>Fiche de cours</span>
                            </div>
                            <div className="option-item" onClick={() => handleOptionClick("exams")}>
                                <div className="icon test-icon"></div>
                                <span className='auth_lesson'>Exercices Videos</span>
                            </div>
                            <div className="option-item" onClick={() => handleOptionClick("exercices")}>
                                <div className="icon exercises-icon"></div>
                                <span className='auth_lesson'>Exercices corrigés</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {view === 'videos' && (
                <div className="video-layout">
                    <button onClick={handleBackToPanel} className="back-button">Retour</button>
                    <div className="videos-content">
                        {playingVideo && (
                            <div className="playing-video">
                                <h2>Lire: {playingVideo.subtitle}</h2>
                                <iframe
                                    width="100%"
                                    height="600px"
                                    src={playingVideo.video_link}
                                    title={playingVideo.subtitle}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                        <div className="video-list">
                            {videos.length > 0 ? (
                                videos.map((video) => (
                                    <div
                                        key={video.id}
                                        className={`video-item ${playingVideo && playingVideo.id === video.id ? 'playing' : ''}`}
                                        onClick={() => handleVideoClick(video)}
                                    >
                                        <h4 className='voir'>{video.subtitle}</h4>
                                    </div>
                                ))
                            ) : (
                                <div className="no-videos">
                                    <h1>No Videos Available</h1>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {view === 'pdfs' && (
                
                <div className="pdf-layout">
                    <button onClick={handleBackToPanel} className="back-button">Retour</button>
                    <div className="pdf-content">
                        {pdfs.length > 0 ? (
                            pdfs.map((pdf) => (
                                <div key={pdf.id} className="pdf-item">
                                    <h4 className='voir'>{pdf.subtitle}</h4>
                                    <a href={pdf.pdf_link} target="_blank" rel="noopener noreferrer" className="pdf-link">
                                        <button>Voir PDF</button>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <div className="no-pdfs">
                                <h1>No PDFs Available</h1>
                            </div>
                        )}
                    </div>
                </div>
            )}

{view === 'cours' && (
    <div className="cours-layout">
        <button onClick={handleBackToPanel} className="back-button">Retour</button>
        <div className="cours-content">
            {cours.length > 0 ? (
                cours.map((item) => (
                    <div key={item.id} className="cours-item">
                        <h4 className='voir'>{item.subtitle}</h4>
                        <br />
                        {/* Ensure item.cours is a valid URL before rendering */}
                        {item.course_link ? (
                            <a href={item.course_link} target="_blank" rel="noopener noreferrer" className="cours-link">
                                <button className="button-style">Voir Cours</button> {/* Use a styled button if desired */}
                            </a>
                        ) : (
                            <span className="no-link">Link not available</span> // Handle no link case
                        )}
                    </div>
                ))
            ) : (
                <div className="no-cours">
                    <h1>No Courses Available</h1>
                </div>
            )}
        </div>
    </div>
)}


            {/* {view === 'exams' && (
                <div className="exams-layout">
                    <button onClick={handleBackToPanel} className="back-button">Retour</button>
                    <div className="exams-content">
                        {exams.length > 0 ? (
                            exams.map((item) => (
                                <div key={item.id} className="exam-item">
                                    <h4 className='voir'>{item.subtitle}</h4>
                                    <a href={item.exercice_video_link} target="_blank" rel="noopener noreferrer" className="exam-link">
                                        <button>Voir Exam</button>
                                    </a>
                                    
                                </div>
                            ))
                        ) : (
                            <div className="no-exams">
                                <h1>No Exams Available</h1>
                            </div>
                        )}
                    </div>
                </div>
            )} */}
{/* {view === 'exams' && (
    <div className="exams-layout">
        <button onClick={handleBackToPanel} className="back-button">Retour</button>
        <div className="exams-content">
            {selectedExam ? ( // Check if an exam is currently selected
                <div className="playing-exam">
                    <h2>Lire: {selectedExam.subtitle}</h2>
                    <iframe
                        width="100%"
                        height="600px"
                        src={selectedExam.exercice_video_link}
                        title={selectedExam.subtitle}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            ) : pdfs.length > 0 ? (
                pdfs.map((item) => (
                    <div
                        key={item.id}
                        className={`exam-item ${selectedExam && selectedExam.id === item.id ? 'playing' : ''}`}
                        onClick={() => handleExamClick(item)} // Click handler to set the selected exam
                    >
                        <h4 className='voir'>{item.subtitle}</h4>
                    </div>
                ))
            ) : (
                <div className="no-exams">
                    <h1>No Exams Available</h1>
                </div>
            )}
        </div>
    </div>
)} */}

{view === 'exams' && (
  <div className="exams-layout">
    <button onClick={handleBackToPanel} className="back-button">← Back to Panel</button>

    <div className="exam-container">

      {/* Video List on the Left */}
      <div className="exam-list">
        {pdfs.length > 0 ? (
          pdfs.map((item) => (
            <div
              key={item.id}
              className={`exam-item ${selectedExam && selectedExam.id === item.id ? 'active' : ''}`}
              onClick={() => handleExamClick(item)}
            >
              <div className="exam-thumbnail">
                <img src={item.thumbnail} alt={item.subtitle} />
              </div>
              <h4 className="exam-title">{item.subtitle}</h4>
            </div>
          ))
        ) : (
          <div className="no-exams">
            <h1>No Exams Available</h1>
          </div>
        )}
      </div>

      {/* Active Video in the Center */}
      <div className="exam-content">
        {selectedExam ? (
          <div className="active-exam">
            <h2 className="exam-subtitle">{selectedExam.subtitle}</h2>
            <iframe
              className="responsive-iframe"
              src={selectedExam.exercice_video_link}
              title={selectedExam.subtitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <div className="no-selected-exam">
            <h1>Selectionner exercice pour regarder</h1>
          </div>
        )}
      </div>

    </div>
  </div>
)}


            {view === 'message' && (
                <div className="message-layout">
                    <button onClick={handleBackToPanel} className="back-button">Retour</button>
                    <div className="message-content">
                        <h1>{message}</h1>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllLessons;
























