import {React, useState, useEffect, useRef} from 'react'

import {Link, useNavigate} from "react-router-dom"

import axios from "axios"

import "../css/config_inscri.css"




const submit_data_func = async(e) => {
    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    try{

   
    const URI_REQ = await axios.post(`${process.env.REACT_APP_HOST}new/${ e.target.previousElementSibling.name}`, {title: e.target.previousElementSibling.value}, {headers})
    if(URI_REQ.status === 200) {
        const time_id = setTimeout(() => {
            const heading_title = e.target.previousElementSibling.previousElementSibling.textContent
            e.target.previousElementSibling.previousElementSibling.textContent = "Done Successfully !"
            e.target.previousElementSibling.previousElementSibling.style.setProperty("color", "green", "important")
            e.target.previousElementSibling.value = ""

            setTimeout(()  => {
                    clearTimeout(time_id)
                    e.target.previousElementSibling.previousElementSibling.textContent = heading_title
                    e.target.previousElementSibling.previousElementSibling.style.setProperty("color", "black", "important")

            },1500)
        },1500)
    }
}catch(err){
    if(err) {
        const time_id_session = setTimeout(() => {
            const heading_title = e.target.previousElementSibling.previousElementSibling.textContent
            e.target.previousElementSibling.previousElementSibling.textContent = "Try Again Please !"
            e.target.previousElementSibling.previousElementSibling.style.setProperty("color", "red", "important")
            e.target.previousElementSibling.value = ""

            setTimeout(()  => {
                    clearTimeout(time_id_session)
                    e.target.previousElementSibling.previousElementSibling.textContent = heading_title
                    e.target.previousElementSibling.previousElementSibling.style.setProperty("color", "black", "important")

            },1500)
        },1500)
    }
}
}


const NewLevel = () => {

    
    return(
        <>
            <section className='configure_inscription'>
                <div className='level-config'>
                    <h1>Levels Management</h1>
                    <input type="text" placeholder="Level Title:" name="levels"/>
                    <button className='submit-level' onClick={submit_data_func}>Add Level</button>
                </div>
            </section>
           
        </>
    )
}


const NewCourse = () => {
    return(
        <>
            <section className='configure_inscription'>
                <div className='course-config'>
                    <h1>Courses Management</h1>
                    <input type="text" placeholder="Course Title:" name="courses"/>
                    <button className='submit-course' onClick={submit_data_func}>Add Course</button>
                </div>
            </section>
        </>
    )
}


const NewOption = () => {
    return(
        <>
            <section className='configure_inscription'>
                <div className='option-config'>
                    <h1>Options Management</h1>
                    <input type="text" placeholder="Option Title:" name="options"/>
                    <button className='submit-option' onClick={submit_data_func}>Add Option</button>
                </div>
            </section>
            
        </>
    )
}


export  {
    NewCourse, NewLevel, NewOption
}