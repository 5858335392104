import React, {useState, useEffect, useMemo, useRef} from "react"
import {Link, useNavigate} from "react-router-dom"
import axios from "axios"
import "../css/youtube.css"

const Lessons  = props => {
    const [lessons, setLessons] = useState([]);
    const framing = useRef()
    useEffect(() => {
        const showLessons  = async() => {
            try{
                const myLessons = await axios.get(`${process.env.REACT_APP_HOST}api/subjects`)
                setLessons(myLessons.data)
            }catch(err) {
                if(err) console.log(err)
            }
        }

        showLessons()
    },[])

  
    return(
        <>
         {lessons.map((lesson) => (
                   
            <div className="video-preview">
                <h1>{lesson.title}</h1>
                <p>{lesson.subtitle}</p>
                <iframe ref={framing} src={lesson.video_link} width="800" height="500" frameborder="0" allowFullScreen loading="lazy" allow></iframe>
            </div>
             ))}

{/* <iframe width="800" height="500" src="https://ds2play.com/e/odrlkcynwg4lgirp41t9gg73kerqxumt
" scrolling="no" frameborder="0" allowFullScreen="true"></iframe> */}
{/* <iframe width="640" height="360" src="https://short.ink/_OqFEMQD4?image=https://i0.wp.com/www.sciencenews.org/wp-content/uploads/2023/06/062623_CG_Megalodon_feat.jpg?fit=1030%2C580&ssl=1" frameborder="0" scrolling="0" allowFullScreen></iframe> */}
        
        </>
    )
}

export default Lessons
