import React, {useState, useEffect, useRef} from 'react'
import Owner from "./Owner"
import {useNavigate, Link} from "react-router-dom"
import axios from "axios"



const ManSubjects = props => {
    const navigate = useNavigate()
    const [subject, setSubject] = useState({
        subject_name:"",
        subject_level: ""
    })

    const [Levels, setLevels] = useState([])
  const [MyCourses, setCourses] = useState([])
  const [Options, setOptions] = useState([])
  const [offers, setOffers] = useState([])
  useEffect(() => {


    const submit_data_func = async (e) => {

      try {


        const forLevels = await axios.get(`${process.env.REACT_APP_HOST}new/levels`)
        const forOptions = await axios.get(`${process.env.REACT_APP_HOST}new/options`)
        const forCourses = await axios.get(`${process.env.REACT_APP_HOST}new/courses`)
        const forOffers = await axios.get(`${process.env.REACT_APP_HOST}app/active_offers`)
        if (forLevels.status === 200) {
          setLevels(forLevels.data)
        }

        if (forOptions.status === 200) {
          setOptions(forOptions.data)
        }

        if (forCourses.status === 200) {
          setCourses(forCourses.data)
        }

        if (forOffers.status === 200) {
          setOffers(forOffers.data)
        }

      } catch (err) {
        if (err) {
          console.log(err)
        }
      }
    }
    submit_data_func()
  }, [])


    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };



    const handleChange = (e) => {
        setSubject(prev => ({...prev, [e.target.name]: e.target.value}))
    }


    const addSubject = async(e) => {
        e.preventDefault()

        try{
            const sendSubject = await axios.post(`${process.env.REACT_APP_HOST}subjects`, subject, {headers})
            navigate("/admin/owner/settings")
        }catch(err) {
            console.log(err)
        }

    }



    useEffect(() => {
        // console.log(subject)

    }, [subject])


    return(
        <>
            <Owner/>
            <div className='add-subjects-container'>
                <form className='add-subjects-form'>
                    <input type="text" placeholder='Subject Name: ' name='subject_name' onChange={handleChange}/>
                    <select name="subject_level" onChange={handleChange}>
                        <option name="" value="">Selectionner Niveau:</option>
                        {Options.map((option) => (
                        <option key={option.id} value={option.title} name="option_field">{option.title}</option>

            ))}
                    </select>
                    <button className='set-subject' onClick={addSubject}>Ajouter une Matiére</button>
                </form>
            </div>
        </>
    )
}


export default ManSubjects