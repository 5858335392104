import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../css/owner.css';
import user from '../images/sidebar-imgs/user.png';
import member from '../images/sidebar-imgs/members.png';
import dash from '../images/sidebar-imgs/dashboard.png';
import star from '../images/sidebar-imgs/star.png';
import reports from '../images/sidebar-imgs/reports.png';
import chat from '../images/sidebar-imgs/video-chat.png';
import project from '../images/sidebar-imgs/projects.png';
import message from '../images/sidebar-imgs/messages.png';
import rewards from '../images/sidebar-imgs/rewards.png';
import logouted from '../images/sidebar-imgs/logout.png';
import settings from '../images/sidebar-imgs/setting.png';
import { useAuth } from './AuthContext';
import { useNavigate } from "react-router-dom"
const Owner = (props) => {
  const { authenticated, setAuthenticated, authenticatedAdmin, setAuthenticatedAdmin, login, loginAdmin, logoutAdmin, logout } = useAuth();
  const [ownerUser, setUser] = useState({});
  const navigate = useNavigate()
  const token = localStorage.getItem("userId");
  const headers = {
          Authorization: `Bearer ${token}`,
      };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (document.location.pathname.split('/').length >= 4 && document.location.pathname.split('/')[2] !== "members" && document.location.pathname.split('/')[2] !== "messages" && document.location.pathname.split('/')[2] !== "students" && document.location.pathname.split('/')[2] !== "settings" && document.location.pathname.split('/')[2] !== "reports") {
          const role = localStorage.getItem("userRole");
          const user = localStorage.getItem("userName");
          if (!authenticatedAdmin) {
            navigate("/unauthenticated")
          }
          const fetchData = await axios.get(`${process.env.REACT_APP_HOST}admin/${role}/${user}`, {headers});
          setUser(fetchData.data);
        } else if (document.location.pathname.split('/').length <= 3 && document.location.pathname.split('/')[2] !== "members" && document.location.pathname.split('/')[2] !== "messages" && document.location.pathname.split('/')[2] !== "students" && document.location.pathname.split('/')[2] !== "settings" && document.location.pathname.split('/')[2] !== "reports") {
          if (!authenticatedAdmin) {
            navigate("/unauthenticated")
          }
          const role = localStorage.getItem("userRole");
          const fetchData = await axios.get(`${process.env.REACT_APP_HOST}admin/${role}`, {headers});
          setUser(fetchData.data);
        }


      } catch (err) {
        console.log(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    // console.log(ownerUser);
    // console.log("hello 0", ownerUser[0]?.admin_user);
  }, [ownerUser]);




  const handleLogout = () => {
    logoutAdmin()
    logout()
    navigate("/")
    localStorage.clear()
  };

  return (
    <section className="owner-container">
      <div className="owner-sidebar">
        <div className="user">
          <img src={user} alt="" className="user-img" />
          <div>
            <h2>{ownerUser[0]?.admin_user}</h2>
            <p>{ownerUser[0]?.admin_email}</p>
          </div>
          <img src={star} alt="" className="star-img" />
        </div>
        <ul className="owner-dash-ul">
          <Link to="/admin/owner/dashboard">
            <li>
              <img src={dash} alt="" />
              <p>Dashboard</p>
            </li>
          </Link>
          <Link to="/admin/owner/reports">
            <li>
              <img src={reports} alt="" />
              <p>Reports</p>
            </li>
          </Link>
          <Link to="/admin/owner/students">
            <li>
              <img src={rewards} alt="" />
              <p>Students</p>
            </li>
          </Link>
          <Link to="/admin/owner/messages">
            <li>
              <img src={message} alt="" />
              <p>Messages</p>
            </li>
          </Link>
          <Link to="/admin/owner/members">
            <li>
              <img src={member} alt="" />
              <p>Core Members</p>
            </li>
          </Link>
          <Link to="/admin/owner/settings">
            <li>
              <img src={settings} alt="" />
              <p>Settings</p>
            </li>
          </Link>
          <Link to="/rules">
            <li>
              <img src={project} alt="" />
              <p>Setup Offers</p>
            </li>
          </Link>
        </ul>
        
        <ul className="owner-dash-ul">
          <li>
            <img src={logouted} alt="" />
            <p onClick={handleLogout}>Logout</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Owner;
