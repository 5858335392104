import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

// Fix import statement

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedAdmin, setAuthenticatedAdmin] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated from storage on component mount
    const storedAuth = localStorage.getItem('authenticated');
    const storedAdminAuth = localStorage.getItem('authenticatedAdmin');
    const storedUserId = localStorage.getItem('userId');

    if (storedAuth && storedUserId) {
      setAuthenticated(true);
      setUserId(storedUserId);
      if (storedUserId) {
        try {
          const decodedToken = jwtDecode(storedUserId); // Fix token decoding
          // console.log('Decoded Token:', decodedToken);
          const expirationTime = decodedToken.exp * 1000;
          // console.log('Expiration Time:', expirationTime);
          const expirationTimeInMilliseconds = expirationTime;
          const expirationTimeInMinutes = expirationTimeInMilliseconds / (1000 * 60);
          // console.log(expirationTimeInMinutes)
          // console.log('Decoded Token:', decodedToken);

          // console.log('Expiration Time:', expirationTime);


          if (Date.now() < expirationTime) {
            // Token is not expired, include it in the request headers
            const headers = {
              Authorization: `Bearer ${storedUserId}`,
            };
            console.log('Token Valid User !!!!!!!!!!');

            // Make authenticated request using 'headers'
            // ...
          } else {
            // Token is expired, handle reauthentication or redirect to the login page
            console.log('Token has expired. Redirect to login page.');
            setUserId(null);
            setAuthenticated(false);
            localStorage.removeItem('authenticated');
            localStorage.removeItem('userId');
          }
        } catch (error) {
          console.error('Error decoding JWT:', error);
          setUserId(null);
          setAuthenticated(false);
          localStorage.removeItem('authenticated');
          localStorage.removeItem('userId');
          // Handle decoding error, e.g., token is not a valid JWT
        }
      } else {
        // Token is not present, handle authentication or redirect to the login page
        console.log('No Token Found');
        setUserId(null);
        setAuthenticated(false);
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userId');
      }
    }

    if (storedAdminAuth && storedUserId) {
      setAuthenticatedAdmin(true);
      setUserId(storedUserId);
      if (storedUserId) {
        try {
          const decodedToken = jwtDecode(storedUserId); // Fix token decoding
          const expirationTime = decodedToken.exp * 1000;
          // console.log('Decoded Token:', decodedToken);

          // console.log('Expiration Time:', expirationTime);
          const expirationTimeInMilliseconds = expirationTime;
          const expirationTimeInMinutes = expirationTimeInMilliseconds / (1000 * 60);
          // console.log(expirationTimeInMinutes)
          if (Date.now() < expirationTime) {
            // Token is not expired, include it in the request headers
            const headers = {
              Authorization: `Bearer ${storedUserId}`,
            };

            console.log('Authorized Admin Token Welcome !!!!!!!!!!!');

            // Make authenticated request using 'headers'
            // ...
          } else {
            // Token is expired, handle reauthentication or redirect to the login page
            console.log('Token has expired. Redirect to login page.');
            setUserId(null);
            setAuthenticatedAdmin(false);
            localStorage.removeItem('authenticatedAdmin');
            localStorage.removeItem('userId');
          }
        } catch (error) {
          console.error('Error decoding JWT:', error);
          setUserId(null);
          setAuthenticatedAdmin(false);
          localStorage.removeItem('authenticatedAdmin');
          localStorage.removeItem('userId');
          // Handle decoding error, e.g., token is not a valid JWT
        }
      } else {
        // Token is not present, handle authentication or redirect to the login page
        console.log('No Token');
        setUserId(null);
        setAuthenticatedAdmin(false);
        localStorage.removeItem('authenticatedAdmin');
        localStorage.removeItem('userId');
      }
    }
  }, [userId]); // Add userId to the dependency array

  const login = (id) => {
    setUserId(id);
    setAuthenticated(true);
    localStorage.setItem('authenticated', 'true');
    localStorage.setItem('userId', id);
  };

  const logout = () => {
    setUserId(null);
    setAuthenticated(false);
    localStorage.removeItem('authenticated');
    localStorage.removeItem('userId');
  };

  const loginAdmin = (id) => {
    setUserId(id);
    setAuthenticatedAdmin(true);
    localStorage.setItem('authenticatedAdmin', 'true');
    localStorage.setItem('userId', id);
  };

  const logoutAdmin = () => {
    setUserId(null);
    setAuthenticatedAdmin(false);
    localStorage.removeItem('authenticatedAdmin');
    localStorage.removeItem('userId');
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        authenticatedAdmin,
        setAuthenticatedAdmin,
        userId,
        login,
        logout,
        loginAdmin,
        logoutAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
