import React from 'react';
import Owner from "./Owner"
import { useRef, useState, useEffect } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "../css/subjects.css"


const Courses = props => {
    const navigate = useNavigate()
    const form = useRef()
    const [courses, setCourses] = useState({})

    const [newCourse, setNewCourse] = useState({
        title: "",
        src: "",
        duration: "",
        description: "",
        offer: ""
    })

    const token = localStorage.getItem("userId");
            const headers = {
                Authorization: `Bearer ${token}`,
            };

    useEffect(() => {
        const getCourses = async (e) => {
            try {
                const allCourses = await axios.get(`${process.env.REACT_APP_HOST}courses`, {headers})
                setCourses(allCourses.data)

            } catch (err) {
                console.error({ error: err })
            }
        }

        getCourses()
    }, [])


    const handleChange = (e) => {
        setNewCourse(prev => ({ ...prev, [e.target.name]: e.target.value }))
        // console.log(newCourse)
    }



    const handleCourses = async (e) => {
        try {
            const allCourses = await axios.post(`${process.env.REACT_APP_HOST}api/courses/videos/${form.current.id}`, newCourse, {headers})
            // console.log(allCourses.status)

        } catch (err) {
            console.error({ error: err })
        }
    }

    const showSetup = (e) => {
        e.preventDefault()
        form.current.style.setProperty("display", 'flex')
        form.current.id = e.currentTarget.previousElementSibling.previousElementSibling.textContent
    }
    return (
        <>
            <Owner />
            <div className='grand-subjects-container'>
                <div className='subjects-container'>
                    {courses.length > 0 ? courses.map(course => (
                        <div key={course.id}>
                            <h1>{course.course_name}</h1>
                            <p>{course.course_price}DH</p>
                            <button id={course.id} onClick={showSetup}>Manage</button>
                        </div>
                    )) : console.log("not Found")}
                </div>
            </div>
            <div className='setup-subject' ref={form}>
                <form className='setup-form'>
                    <input type="text" placeholder='title:' name='title' onChange={handleChange} />
                    <input type="text" placeholder='video source:' name='src' onChange={handleChange} />
                    <input type="number" placeholder='duration:' name='duration' onChange={handleChange} />
                    <input type="text" placeholder='offer:' name='offer' onChange={handleChange} />
                    <input type="text" placeholder='description:' name='description' onChange={handleChange} />
                    <button onClick={handleCourses}>Add Course</button>
                </form>
            </div>
        </>
    )
}


export default Courses



