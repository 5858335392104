


import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom"
import axios from 'axios';
import "../css/rules.css";


const Rules = () => {
    const [offers, setOffers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState({});
    const token = window.localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate()

    useEffect(() => {
        const loadData = async () => {
            try {
                // Fetch offers
                const offersResponse = await axios.get(`${process.env.REACT_APP_HOST}offers`, { headers });
                if (offersResponse.status === 200) {
                    const offersData = Array.isArray(offersResponse.data) ? offersResponse.data : offersResponse.data.data;
                    setOffers(offersData);
                } else {
                    console.error("Error During Loading Data From Offers Endpoint");
                }

                // Fetch subjects
                const subjectsResponse = await axios.get(`${process.env.REACT_APP_HOST}subjects`, { headers });
                if (subjectsResponse.status === 200) {
                    const subjectsData = Array.isArray(subjectsResponse.data) ? subjectsResponse.data : subjectsResponse.data.data;
                    setSubjects(subjectsData);
                } else {
                    console.error("Error During Loading Data From Subjects Endpoint");
                }
            } catch (err) {
                console.error("Axios Error During Loading Data:", err);
            }
        };

        loadData();
    }, []);

    const handleCheckboxChange = (offerId, subjectId) => {
        setSelectedSubjects(prevState => ({
            ...prevState,
            [offerId]: {
                ...prevState[offerId],
                [subjectId]: !prevState[offerId]?.[subjectId]
            }
        }));
    };

    const handleValiderClick = async (offer) => {
        try {
            const checkedSubjects = Object.keys(selectedSubjects[offer.id] || {}).filter(subjectId => selectedSubjects[offer.id][subjectId]);
            const selectedSubjectNames = checkedSubjects.map(subjectId => subjects.find(subject => subject.id === parseInt(subjectId)).subject_name);
            
            await axios.post(`${process.env.REACT_APP_HOST}setup_offers`, {
                offer_title: offer.offer_title,
                subjects: selectedSubjectNames
            }, { headers });

            alert('Offer details successfully submitted!');
        } catch (err) {
            console.error("Error submitting offer details:", err);
            alert('Failed to submit offer details.');
        }
    };

    return (
        <>
        <div className="dashboard">
            <header className="dashboard-rules-header">
                <h1>Offers and Subjects Dashboard</h1>
            </header>
            <button className='back-to-o' onClick={() => {navigate("/admin/owner")}}>Back To Dashboard</button>
            <div className="dashboard-content">
                <section className="offers-rules-section">
                    <h2>Offers</h2>
                    {offers.length > 0 ? (
                        <ul>
                            {offers.map((offer) => (
                                <li key={offer.id} className="offer-rules-item">
                                    <h3 className='rules'>{offer.offer_title}</h3>
                                    <div className="checkbox-rules-group">
                                        {subjects.length > 0 ? (
                                            subjects.map((subject) => (
                                                <div key={subject.id} className="checkbox-rules-item">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name={`subject-${subject.id}`}
                                                            value={subject.id}
                                                            checked={selectedSubjects[offer.id]?.[subject.id] || false}
                                                            onChange={() => handleCheckboxChange(offer.id, subject.id)}
                                                        />
                                                        {subject.subject_name}
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <p className='rules'>No subjects available.</p>
                                        )}
                                    </div>
                                    <button className='rules' onClick={() => handleValiderClick(offer)}>Valider</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className='rules'>No offers available.</p>
                    )}
                </section>
            </div>
        </div>
        </>
        
    );
};

export default Rules;

