import React, {useState, useEffect, useRef} from 'react';
import Owner from "./Owner"
import {useNavigate, Link} from "react-router-dom"
import axios from "axios"


const ManCourses = props => {
    const navigate = useNavigate()

    const [course, setCourse] = useState({
        course_name:"",
        course_price: ""
    })

    const token = localStorage.getItem("userId");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleChange = (e) => {
        setCourse(prev => ({...prev, [e.target.name]: e.target.value}))
    }


    const addCourse = async(e) => {
        e.preventDefault()

        try{
            const sendCourse = await axios.post(`${process.env.REACT_APP_HOST}courses`, course, {headers})
            navigate("/admin/owner/settings")
        }catch(err) {
            console.log(err)
        }

    }

    return(
        <>
            <Owner/>
            <div className='add-subjects-container'>
                <form className='add-subjects-form'>
                    <input type="text" placeholder='Course Name: ' name='course_name' onChange={handleChange}/>
                    <input type='number' min={50} max={10000} name="course_price" placeholder='course_price' onChange={handleChange}/>
                      
                    <button className='set-subject' onClick={addCourse}>Ajouter une cours</button>
                </form>
            </div>
        </>
    )
}


export default ManCourses