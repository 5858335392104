import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/reservation.css";
import axios from "axios";

const Reservation = () => {
    const navigate = useNavigate()
    const [reservation, setReservation] = useState({
        lastName: '',
        name: '',
        level: '',
        opt: '',
        phone: '',
        subject: ''
    });

    const handleChange = (e) => {
        setReservation(prev => ({ ...prev, [e.target.name]: e.target.value }));
        // console.log(reservation)
    };

    const sendReservation = async (e) => {
        e.preventDefault(); // Prevent form submission
        try {
            await axios.post(`${process.env.REACT_APP_HOST}reservation/prof`, reservation);
            setTimeout(() => {
                navigate("/redirect/thanks")
            }, 1200)
        } catch (err) {
            console.log("Server Axios Error: ", new Error(err));
        }
    };

    return (
        <div className="container">
            <section className="reservation">
                <div className="reservation">
                    <h1>Reservation d'une Matiére</h1>
                    <input
                        type="text"
                        placeholder="Votre Prénom: "
                        name="lastName"
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        placeholder="Votre Nom: "
                        name="name"
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        placeholder="Votre Niveau: "
                        name="level"
                        value={reservation.level}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        placeholder="Votre Option: "
                        name="opt"
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        placeholder="Votre Num: "
                        name="phone"
                        onChange={handleChange}
                    />
                    <select
                        id="reser-field"
                        name="subject"
                        required
                        className="reser-field"
                        onChange={handleChange}
                    >
                        <option value="" disabled>Selectionner une matière: </option>
                        <option value="Math">Math</option>
                        <option value="PC">PC</option>
                        <option value="SVT">SVT</option>
                        <option value="Économie et Organisation">Économie et Organisation</option>
                        <option value="Économie générale et Statistiques">Économie générale et Statistiques</option>
                        <option value="Comptabilité">Comptabilité</option>
                        <option value="Philosophie">Philosophie</option>
                    </select>
                    <button className="reserver" onClick={sendReservation}>Réservez Maintenant</button>
                </div>
            </section>
            <aside className="rv-prooof">
                <h3>Système de Réservation</h3>
            </aside>
        </div>
    );
}

export default Reservation;
